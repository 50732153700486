import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { motion } from "framer-motion";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
const ApplicantDetailPage = () => {
    const [ViewApplicant, setEditJob] = useState({});
    const [ApplicantQuestionAnswer, setApplicantQuestionAnswer] = useState({});
    const { id } = useParams();
    //Get Applicant
    useEffect(() => {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/applicant/${id}`).then((response) => {
            setEditJob(response.data.applicant || {});
            setApplicantQuestionAnswer(response.data.answer || {}); 
        });
    }, [id]);
    return (
        <>
            <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <Link to="/applicant" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                Applicant
                            </Link>
                        </div>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">View Applicant</span>
                        </div>
                    </li>
                </ol>
                <Link
                    to="/applicant"
                    type="button"
                    className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                >
                    <span className=" mt-1">
                        <IoMdArrowRoundBack />
                    </span>{" "}
                    <span className="pl-2">Back</span>
                </Link>
            </nav>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="min-h-screen">
                <div className="bg-[#ffae37] text-white py-16">
                    <div className="container mx-auto text-center">
                        <h1 className="text-4xl font-bold mb-4">{ViewApplicant.first_name} {ViewApplicant.last_name}</h1>
                    </div>
                </div>
                <div className="container mx-auto mt-8">
                    <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} className="bg-white p-8 shadow-lg rounded-md">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <p className="text-gray-800 mb-2">
                                    <span className="font-semibold">First Name:</span> {ViewApplicant.first_name}
                                </p>
                                <p className="text-gray-800 mb-2">
                                    <span className="font-semibold">Email:</span> {ViewApplicant.email}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-800 mb-2">
                                    <span className="font-semibold">Last Name:</span> {ViewApplicant.last_name}
                                </p>
                                <p className="text-gray-800 mb-2">
                                    <span className="font-semibold">Phone #:</span> {ViewApplicant.phone}
                                </p>
                            </div>
                        </div>
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold mb-2">CV</h3>
                            <iframe
                                src={`https://projects.bzbeetech.com/busybeetech/public/upload/${ViewApplicant.normal_cv}`}
                                style={{width: '100%', height: '700px'}}
                                frameBorder="0"
                            >
                                Your browser does not support iframes.
                            </iframe>
                        </div>

                        {Array.isArray(ApplicantQuestionAnswer) && ApplicantQuestionAnswer.map((q, index) => (
                            <div className="mt-8">
                                <h3 className="text-xl font-semibold mb-2">Question # {index+1} :</h3>
                                <p className="text-gray-800">{q.question}</p>
                                <p className="text-gray-800">{q.answer}</p>
                            </div>
                        ))}
                        
                    </motion.div>
                </div>
            </motion.div>
        </>
    );
};
export default ApplicantDetailPage;