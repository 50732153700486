import { React, useState , useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEditDocument } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditPolicy = () => {
    const [getPolicy, setEditPolicy] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();
    const handleApiError = (error) => {
        if (error.isAxiosError && !error.response) {
            toast.error('Network is not stable');
        } else {
            console.error('API Error:', error.response.data);
            toast.error('An unexpected error occurred while processing the request.');
        }
    };
    const fetchData = async () => {
        try {
            const response = await axios.get(`https://projects.bzbeetech.com/busybeetech/api/policy/${id}/edit`);
            setEditPolicy( response.data.policy || '' );
        } catch (error) {
            console.error(error);
        }
    };
    const handleSubmit1 = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(`https://projects.bzbeetech.com/busybeetech/api/policy/${id}`, getPolicy);
            if (response.data.status === 'success') {
                toast.success(response.data.message);
                setTimeout(() => navigate('/policy'), 1500);
            } else if (response.data.errors) {
                Object.entries(response.data.errors).forEach(([field, messages]) => {
                    messages.forEach((message) => toast.error(`${field}: ${message}`));
                });
            }
        } catch (error) {
            handleApiError(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleQuillChange = (value) => {
        setEditPolicy((prevPolicy) => ({
            ...prevPolicy,
            policy: value,
        }));
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];
    
    return (
        <>
            <form onSubmit={handleSubmit1}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                <li className="inline-flex items-center">
                                    <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <Link to="/meeting_schedule" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                            Polices
                                        </Link>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Edit Policy</span>
                                    </div>
                                </li>
                            </ol>
                            <Link
                                to="/policy"
                                type="button"
                                className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                            >
                                <span className=" mt-1">
                                    <IoMdArrowRoundBack />
                                </span>{" "}
                                <span className="pl-2">Back</span>
                            </Link>
                        </nav>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-full">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Description <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <ReactQuill
                                        name="policy"
                                        theme="snow"
                                        value={getPolicy.policy}
                                        onChange={handleQuillChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                        type="submit"
                        className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                    >
                        Update
                        <span className="ml-2 mt-1">
                            <MdEditDocument />
                        </span>
                    </button>
                </div>
            </form>
            
            <ToastContainer />
        </>
    );
};

export default EditPolicy;
