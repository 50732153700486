import React from 'react'
import { Fragment, useState , useEffect} from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from "../logo.png"; 
import Mobilelogo from "../logo-main.png"; 
import { LiaUsersSolid } from "react-icons/lia";
import { LuLogOut } from "react-icons/lu";
import { FaUserCog } from "react-icons/fa";
import { Bars3Icon,BellIcon,} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {Cog6ToothIcon,HomeIcon,UsersIcon,XMarkIcon,} from '@heroicons/react/24/outline'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdControlCamera } from "react-icons/md";
import { GrWorkshop } from "react-icons/gr";
import { TbGitPullRequest } from "react-icons/tb";
import axios from "axios";
import { FaBuildingUser } from "react-icons/fa6";
import { GiVideoConference } from "react-icons/gi";
import { BiSolidReport } from "react-icons/bi";
import { CiClock1 } from "react-icons/ci";
import { MdPolicy } from "react-icons/md";
import { TbIndentIncrease } from "react-icons/tb";
import { MdOutlineAppRegistration } from "react-icons/md";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function DesktopSidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const [getuser, setGetUser] = useState([]);
  const [getrole, setGetRole] = useState([]);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [setRequest, setGetRequest] = useState([]);
  const [getLeave, setGetLeave] = useState([]);
  const [setApplicantJob, setGetApplicantJob] = useState([]);
  //Get Profile Request
  const setRequestFun = () => {
      try {
          axios.get(`https://projects.bzbeetech.com/busybeetech/api/profile`).then((response) => {
            if (response.data.status === 'success') {
                    setGetRequest(response.data.users);
            } else {
                console.error('API request failed:', response.data.message);
            }
          });
      } catch (error) {
          if (error.isAxiosError && !error.response) {
            toast.error("Network is not stable");    
          } else {
            toast.error("An unexpected error occurred while processing the request.");
          }
      }
  }
  //Get Leave Request
  const FetchLeave = async (id) => {
    try {
      const response = await axios.get(`https://projects.bzbeetech.com/busybeetech/api/leave/create`);
      if (response.data.status === "success") {
        setGetLeave(response.data.user)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
        if (error.isAxiosError && !error.response) {
          toast.error("Network is not stable");    
        } else {
          toast.error("An unexpected error occurred while processing the request.");
        }
    }
  }
  //Get Applicants
  const setApplicants = () => {
    try {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/applicant/create`).then((response) => {
          setGetApplicantJob(response.data.job);
        });
    } catch (error) {
        if (error.isAxiosError && !error.response) {
          toast.error("Network is not stable");    
        } else {
          console.error('API Error:', error.response.data);
          toast.error("An unexpected error occurred while processing the request.");
        }
    }  
  }
  // Local storage Get Data
  var userData = localStorage.getItem('user');
  var user = JSON.parse(userData);
  var userId = user.id;
  const getUser = () => {
    try {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/profile/${userId}`).then((response) => {
          if(response.data.status === "success"){
            var role_id = response.data.employee.role_id;
            setGetUser(response.data.employee);
            getRolePermision(role_id);
          }
        });
    } catch (error) {
        if (error.isAxiosError && !error.response) {
          toast.error("Network is not stable");    
        } else {
          toast.error("An unexpected error occurred while processing the request.");
        }
    }
  };
  const getRolePermision = (role_id) => {
      try {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/role/${role_id}`).then((response) => {
            if(response.data.status === "success"){
              setGetRole(response.data.role);
            }
          }); 
      } catch (error) {
          if (error.isAxiosError && !error.response) {
            toast.error("Network is not stable");    
          } else {
            toast.error("An unexpected error occurred while processing the request.");
          }
      }
  };
  useEffect(() => {
    getUser();
    getRolePermision();
    setRequestFun();
    FetchLeave();
    setApplicants();
  }, []);
  const ProfileImage = getuser.image 
  ? `https://projects.bzbeetech.com/busybeetech/public/upload/${getuser.image}`
  : process.env.PUBLIC_URL + '/profile.webp';
  const getPathname = () => {
    const pathname = location.pathname;
    return pathname.substr(1);
  };
  function logOut() {
    localStorage.removeItem('user');
    localStorage.clear();
    navigate('/')
  }
  return (
    <div>
      {/* mobile sidebar  start */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>
          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" /></button>

                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                  <Link to={'/dashboard'}>
                    <div className="flex h-16 shrink-0 items-center  justify-center">
                        <img src={Mobilelogo} alt="Busy Bee Technologies Logo" className="w-[140px] mt-8 mb-3 object-cover" />
                    </div>
                  </Link>
                  
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">

                          <li>
                            <Link
                              to={'/dashboard'}
                              className={classNames(
                                getPathname() === 'dashboard' ? 'bg-yellow-800 text-white' : 'text-gray-400 hover:text-white hover:bg-yellow-800',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'

                              )}
                            >
                              < HomeIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                              Dashboard
                            </Link>
                          </li>
                          
                            <li>
                              <Link
                                to={'/job'}
                                className={classNames(
                                  (getPathname() === 'job' || getPathname() === 'addjob') ? 'bg-yellow-800 text-white' : 'text-gray-400 hover:text-white hover:bg-yellow-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <UsersIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                Job
                              </Link>
                            </li>
                          
                            <li>
                              <Link
                                to={'/employee'}
                                className={classNames(
                                  (getPathname() === 'employee' || getPathname() === 'addemployee') ? 'bg-yellow-800 text-white' : 'text-gray-400 hover:text-white hover:bg-yellow-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <UsersIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                Employee
                              </Link>
                            </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* mobile sidebar  end */}
      {/* sidebar start  */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="scrollbar-custom flex grow flex-col gap-y-5 overflow-y-auto bg-[#ffae37] px-6 pb-4">
          <Link to={'/dashboard'}>
            <div className="flex h-16 shrink-0 items-center justify-center">
              <img src={logo} alt="Busy Bee Technologies Logo" className="mt-4 w-[110px] object-cover" />
            </div>
          </Link>
          <nav className="flex flex-1 flex-col mt-9">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                  {getrole.role === getuser.role && getrole.edit_employee === "0" && getrole.view_employee === "0" &&
                   getrole.update_profile === "0" && getrole.meeting_schedule === "0" && getrole.view_applicant === "0" && 
                   getrole.post_job === "0" ?(
                    <ul role="list" className="-mx-2 space-y-1">
                      <li>
                        <Link
                          to={'/dashboard'}
                          className={classNames(
                            getPathname() === 'dashboard' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          < HomeIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          Dashboard
                        </Link>
                      </li>
                      <li>
                          <Link
                            to={'/addLeave'}
                            className={classNames(
                              getPathname() === 'addLeave' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'

                            )}
                          >
                            < TbIndentIncrease  className="h-6 w-6 shrink-0" aria-hidden="true" />
                              Leave
                          </Link>
                      </li>
                    </ul>
                    ) : (
                      <ul role="list" className="-mx-2 space-y-1">
                        <li>
                          <Link
                            to={'/dashboard'}
                            className={classNames(
                              getPathname() === 'dashboard' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'

                            )}
                          >
                            < HomeIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                            Dashboard
                          </Link>
                        </li>
                        {getrole.role === getuser.role && getrole.meeting_schedule === "1" && getrole.view_employee === "1" && 
                        getrole.post_job === "1" && getrole.view_applicant === "1" && getrole.update_profile === "1" && 
                        getrole.edit_employee === "1" &&(
                          <>
                            <li className='bg-[#5A3307] text-white rounded-md'>
                              <p className='p-2 text-sm leading-6 font-semibold text-center'>Roles</p>
                            </li>
                            <li>
                              <Link to={'/role'} className={classNames(getPathname() === 'role' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                                < MdControlCamera  className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  Roles & Permissions
                              </Link>
                            </li>
                            <li>
                              <Link to={'/time'} className={classNames(getPathname() === 'time' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                                < CiClock1 className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  Office Time Schedule
                              </Link>
                            </li>
                            <li>
                              <Link to={'/policy'} className={classNames(getPathname() === 'policy' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                                < MdPolicy className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  Company Policies
                              </Link>
                            </li>
                            <li className='bg-[#5A3307] text-white rounded-md'>
                              <p className='p-2 text-sm leading-6 font-semibold text-center'>Request Approvels</p>
                            </li>
                            <li>
                              <Link
                                to={'/leave'}
                                className={classNames(
                                  getPathname() === 'leave' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'

                                )}
                              >
                                < MdOutlineAppRegistration   className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  Leave Approvel
                              </Link>
                            </li>
                            <li>
                              <Link to={'/request'} className={classNames(getPathname() === 'request' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                                < TbGitPullRequest   className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  Profile Update Request
                              </Link>
                            </li>
                          </>
                        )}
                        
                        {getrole.role === getuser.role && getrole.edit_employee === "1" && getrole.view_employee === "1" && 
                        getrole.view_applicant === "1" && getrole.update_profile === "1" &&(
                          <>
                            <li className='bg-[#5A3307] text-white rounded-md'>
                              <p className='p-2 text-sm leading-6 font-semibold text-center'>Members</p>
                            </li>
                            <li>
                              <Link
                                to={'/member'}
                                className={classNames(
                                  getPathname() === 'member' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}>
                                < UsersIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                 Members Management
                              </Link>
                            </li>
                          </>
                        )}
                        
                        <li className='bg-[#5A3307] text-white rounded-md'>
                            <p className='p-2 text-sm leading-6 font-semibold text-center'>Jobs</p>
                        </li>
                        <li>
                            <Link to={'/job'} className={classNames(getPathname() === 'job' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                            < GrWorkshop  className="h-6 w-6 shrink-0" aria-hidden="true" />
                            Job Posting
                          </Link>
                        </li>
                        <li className='bg-[#5A3307] text-white rounded-md'>
                            <p className='p-2 text-sm leading-6 font-semibold text-center'>Employees</p>
                        </li>
                        <li>
                          <Link
                            to={'/employee'}
                            className={classNames(
                              getPathname() === 'employee' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                          >
                            < LiaUsersSolid className="h-6 w-6 shrink-0" aria-hidden="true" />
                            Employee Management
                          </Link>
                        </li>
                        <li className='bg-[#5A3307] text-white rounded-md'>
                            <p className='p-2 text-sm leading-6 font-semibold text-center'>Applicants</p>
                        </li>
                        <li>
                            <Link to={'/applicant'} className={classNames(getPathname() === 'applicant' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                            < FaBuildingUser    className="h-6 w-6 shrink-0" aria-hidden="true" />
                              Applied Applicants
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={'/meeting_schedule'}
                            className={classNames(
                              getPathname() === 'meeting_schedule' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}
                          >
                            < GiVideoConference  className="h-6 w-6 shrink-0" aria-hidden="true" />
                            Meeting Schedule
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={'/report'}
                            className={classNames(
                              getPathname() === 'report' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}
                          >
                            < BiSolidReport   className="h-6 w-6 shrink-0" aria-hidden="true" />
                              Applicants Final Reports
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to={'/attendance'}
                            className={classNames(
                              getPathname() === 'attendance' ? 'bg-yellow-800 text-white' : 'text-white hover:text-white hover:bg-yellow-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'

                            )}
                          >
                            < RiPresentationLine  className="h-6 w-6 shrink-0" aria-hidden="true" />
                              Attendance
                          </Link>
                        </li> */}
                      </ul>
                    )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/* sidebar end  */}
      {/*  topbar start  */}
      <div>
        <div className='lg:pl-72'>
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
              <div className="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
                <div className="flex items-center gap-x-4 lg:gap-x-6 ">
                  {getrole.role == 'Super Admin' ?(
                    <>
                      <div className="relative">
                        <button onClick={() => setIsNotificationsOpen(!isNotificationsOpen)} className="relative z-10 flex items-center text-gray-400 hover:text-gray-500 -m-2.5 p-2.5">
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                          {(setRequest.length > 0 || getLeave.length > 0 || setApplicantJob.length > 0) && (
                            <span className="absolute block h-2 w-2 rounded-full bg-green-500 animated-dot custom-dot-position"></span>
                          )}
                        </button>
                        {isNotificationsOpen && (
                          <div className="absolute right-0 mt-2 w-80 origin-top-right rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="p-4 max-h-96 overflow-y-auto">
                            {Array.isArray(setRequest) ? (
                              setRequest.map((profile) => (
                                <>
                                  <div className="flex items-center space-x-4 p-2 hover:bg-gray-50 -mx-2 border-b-2">
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate">New profile update request received</p>
                                      <p className="text-sm text-gray-500 truncate">{profile.name} {profile.last_name}, wants to update it's profile.</p>
                                    </div>
                                  </div>
                                </>
                              ))
                              ) 
                              : (
                                  <p></p>
                              )
                            }
                            {Array.isArray(getLeave) ? (
                              getLeave.map((leave) => (
                                <>
                                  <div className="flex items-center space-x-4 p-2 hover:bg-gray-50 -mx-2 border-b-2">
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate">New leave application received</p>
                                      <p className="text-sm text-gray-500 truncate">{leave.name} {leave.last_name}, wants leave check it for more details.</p>
                                    </div>
                                  </div>
                                </>
                              ))
                              ) 
                              : (
                                  <p></p>
                              )
                            }
                            {Array.isArray(setApplicantJob) ? (
                              setApplicantJob.map((applicant) => (
                                <>
                                  <div className="flex items-center space-x-4 p-2 hover:bg-gray-50 -mx-2 border-b-2">
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate">New job application received</p>
                                      <p className="text-sm text-gray-500 truncate">{applicant.position}, new candidate application received.</p>
                                    </div>
                                  </div>
                                </>
                              ))
                              ) 
                              : (
                                  <p></p>
                              )
                            }
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ):(
                    <></>
                  )}

                  <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
                    <Menu as="div" className="relative">
                      <Menu.Button className="-m-1.5 flex items-center p-1.5">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full bg-gray-50"
                          src={ProfileImage}
                          alt=""
                        />
                        <span className="hidden lg:flex lg:items-center">
                          <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                            {getuser.name} {getuser.last_name}
                          </span>

                          <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <ul>

                          <Link to="/profile">
                            <li className={classNames(
                                ' ml-3 flex text-gray-400 hover:text-gray-400 cursor-pointer',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                              )}
                              >
                              <span>Profile</span>
                              <span className='pl-2 mt-1'><FaUserCog /></span>
                              </li>
                          </Link>

                            <li className={classNames(
                              ' ml-3 flex text-gray-400 hover:text-gray-400 cursor-pointer ',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'

                            )}
                              onClick={logOut}
                            >
                                <span>Logout</span>
                                <span className='pl-2 mt-1'><LuLogOut /></span>
                            </li>
                          </ul>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
              </div>
            </div>
        </div>
      </div>
      {/*  topbar end  */}
      <ToastContainer />
    </div>
  )
}
export default DesktopSidebar