import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosSave } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

function getBase64(file) {
    return new Promise(function(resolve, reject) {
      const reader = new FileReader();
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
}

function EditProfileData() {
    // Fetch data against the UserId form API

    const [getuser, setGetuser] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [form, setForm] = useState([]);

    // fields onchange
    const initialFormData = {
        name: "",
        last_name: "",
        email: "",
        address: "",
        phone: "",
        gender: "",
        education: "",
        dob: "",
        start_date: "",
        designation: "",
        permanent_address: "",
        cnic: "",
        emergency_name: "",
        emergency_relation: "",
        emergency_phone: "",
        bank_name: "",
        branch: "",
        account_no: "",
        job_type: "",
        job_pay_type: "",
        date_first_pay: "",
        joining_date: "",
    };
    const [formData, setFormData] = useState({ ...initialFormData });
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        if (type === "file") {
            const file = e.target.files[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: file,
            }));
        } else {
            setGetuser((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    function updatePost(e) {
        e.preventDefault();

        try {
            axios.put(`https://projects.bzbeetech.com/busybeetech/api/profile/${id}`, {
                    name: getuser.name,
                    last_name: getuser.last_name,
                    email: getuser.email,
                    phone: getuser.phone,
                    education: getuser.education,
                    dob: getuser.dob,
                    start_date: getuser.start_date,
                    address: getuser.address,
                    designation: getuser.designation,
                    gender: getuser.gender,
                    permanent_address: getuser.permanent_address,
                    cnic: getuser.cnic,

                    //emergence
                    emergency_name: getuser.emergency_name,
                    emergency_relation: getuser.emergency_relation,
                    emergency_phone: getuser.emergency_phone,

                    // bank detail
                    bank_name: getuser.bank_name,
                    branch: getuser.branch,
                    account_no: getuser.account_no,

                    //office use only
                    job_type: getuser.job_type,
                    job_pay_type: getuser.job_pay_type,
                    date_first_pay: getuser.date_first_pay,
                    joining_date: getuser.joining_date,


                    // image code

                    UUID_Formulier: '117F994F-F803-7249-91E9-EE1E7B691DFF',
                    answers: form,
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            navigate("/profile");
                        }, 1500);
                    } else if (response.data.errors) {
                        Object.entries(response.data.errors).forEach(([field, messages]) => {
                            messages.forEach((message) => {
                                toast.error(`${field}: ${message}`);
                            });
                        });
                    }
                });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    }
    
    const handleFileBack = async (e) => {
        const fileFound = e.target.type === 'file' && e.target.files[0];

        if (fileFound) {
        try {
            const result = await getBase64(fileFound);
            setForm([
            ...form,
            {
                UUID_Answer: 'cnic_back',
                Answer: '',
                Document: true,
                Document_Upload: result,
            },
            ]);
        } catch (error) {
            console.error('Error converting file to base64:', error);
        }
        }
    };
    const handleFileFront = async (e) => {
        const fileFound = e.target.type === 'file' && e.target.files[0];

        if (fileFound) {
        try {
            const result = await getBase64(fileFound);
            setForm([
            ...form,
            {
                UUID_Answer: 'cnic_front',
                Answer: '',
                Document: true,
                Document_Upload: result,
            },
            ]);
        } catch (error) {
            console.error('Error converting file to base64:', error);
        }
        }
    };

    //Get Profile Data
    useEffect(() => {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/profile/${id}`).then((response) => {
            setGetuser(response.data.employee);
        });
    }, [id]);

    // profile upload end
    return (
        <>
            <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <Link to="/profile" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                Profile
                            </Link>
                        </div>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Update Profile</span>
                        </div>
                    </li>
                </ol>
                <Link
                    to="/profile"
                    type="button"
                    className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                >
                    <span className=" mt-1">
                        <IoMdArrowRoundBack />
                    </span>
                    <span className="pl-2">Back</span>
                </Link>
            </nav>
            <div>
                <form onSubmit={updatePost} encType="multipart/form-data">
                    <div className="overflow-hidden lg:w-[100%]  md:w-[60%] lg:mt-0 md:mt-0 mt-4 bg-white shadow sm:rounded-lg" style={{width: "100%"}}>
                        <h1 className="text-xl text-gray-700 ml-5 font-bold">General Information:-</h1>
                        <div className="flex pt-5">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        First Name <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="name"
                                            autoComplete="name"
                                            className="block w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.name}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Email Address <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="email"
                                            name="email"
                                            autoComplete="email"
                                            className="block w-full bg-gray-100 cursor-not-allowed rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.email}
                                            readOnly
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Gender <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <select
                                            id="gender"
                                            name="gender"
                                            autoComplete="gender"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                        >
                                            {getuser.gender === "male" && (
                                                <>
                                                    <option value="male" selected>
                                                        Male
                                                    </option>
                                                    <option value="female">Female</option>
                                                </>
                                            )}
                                            {getuser.gender === "female" && (
                                                <>
                                                    <option value="male">Male</option>
                                                    <option value="female" selected>
                                                        Female
                                                    </option>
                                                </>
                                            )}
                                            {getuser.gender === null && (
                                                <>
                                                    <option value="">Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </>
                                            )}
                                        </select>
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        DOB <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="date"
                                            name="dob"
                                            autoComplete="dob"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.dob}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                       Permenant Address <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="permanent_address"
                                            autoComplete="permanent_address"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.permanent_address}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                       CNIC <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="number"
                                            name="cnic"
                                            autoComplete="cnic"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.cnic}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                       CNIC Back<span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="file"
                                            name="cnic_back"
                                            autoComplete="cnic_back"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleFileBack || ""}
                                        />
                                    </dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Last Name <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="last_name"
                                            autoComplete="last_name"
                                            className="block  w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.last_name}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Phone <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="number"
                                            name="phone"
                                            autoComplete="phone"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.phone}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Education <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="education"
                                            autoComplete="education"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.education}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Start Date <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="date"
                                            name="start_date"
                                            autoComplete="start_date"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.start_date}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                       Current Address <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="address"
                                            autoComplete="address"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.address}
                                        />
                                    </dd>
                                </div>
                                
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                       CNIC Front <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="file"
                                            name="cnic_front"
                                            autoComplete="cnic_front"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleFileFront || ""}
                                        />
                                    </dd>
                                </div>
                                
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Designation <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="designation"
                                            autoComplete="designation"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.designation}
                                        />
                                    </dd>
                                </div>
                            
                            </dl>
                        </div>
                        <h1 className="text-xl text-gray-700 ml-5 font-bold">Emergency Contact:-</h1>
                        <div className="flex pt-5">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Name <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="emergency_name"
                                            autoComplete="emergency_name"
                                            className="block w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.emergency_name}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Relationship <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="emergency_relation"
                                            autoComplete="emergency_relation"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.emergency_relation}
                                        />
                                    </dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Phone <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="emergency_phone"
                                            autoComplete="emergency_phone"
                                            className="block  w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.emergency_phone}
                                        />
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <h1 className="text-xl text-gray-700 ml-5 font-bold">Bank Details:-</h1>
                        <div className="flex pt-5">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Bank Name <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="bank_name"
                                            autoComplete="bank_name"
                                            className="block w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.bank_name}
                                        />
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Branch <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="branch"
                                            autoComplete="branch"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.branch}
                                        />
                                    </dd>
                                </div>
                            </dl>
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Account <span className="text-[#d50000]">*</span>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="number"
                                            name="account_no"
                                            autoComplete="account_no"
                                            className="block  w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            onChange={handleInputChange || ""}
                                            value={getuser.account_no}
                                        />
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        {getuser.role === "Employee" || getuser.role === "employee" || getuser.role === "EMPLOYEE" 
                        ?(
                            <>
                                <h1 className="text-xl text-gray-700 ml-5 font-bold hidden">Office use only:-</h1>
                            </>
                        ):(
                            <>
                                <h1 className="text-xl text-gray-700 ml-5 font-bold">Office use only:-</h1>
                                <div className="flex pt-5">
                                    <dl className="divide-y divide-gray-100">
                                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-900">
                                                Job Type <span className="text-[#d50000]">*</span>
                                            </dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                <select
                                                    id="job_type"
                                                    name="job_type"
                                                    autoComplete="job_type"
                                                    className="block w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange || ""}
                                                >
                                                    {getuser.job_type === "full_time" && (
                                                        <>
                                                            <option value="full_time" selected>Full Time</option>
                                                            <option value="part_time">Part Time</option>
                                                            <option value="casual">Casual</option>
                                                        </>
                                                    )}
                                                    {getuser.job_type === "part_time" && (
                                                        <>
                                                            <option value="full_time">Full Time</option>
                                                            <option value="part_time" selected>Part Time</option>
                                                            <option value="casual">Casual</option>
                                                        </>
                                                    )}
                                                    {getuser.job_type === "casual" && (
                                                        <>
                                                            <option value="full_time">Full Time</option>
                                                            <option value="part_time" >Part Time</option>
                                                            <option value="casual" selected>Casual</option>
                                                        </>
                                                    )}
                                                    {getuser.job_type === null && (
                                                        <>
                                                            <option value="full_time">Full Time</option>
                                                            <option value="part_time" >Part Time</option>
                                                            <option value="casual">Casual</option>
                                                        </>
                                                    )}
                                                </select>
                                            </dd>
                                        </div>
                                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-900">
                                                Date of first pay review <span className="text-[#d50000]">*</span>
                                            </dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                <input
                                                    type="date"
                                                    name="date_first_pay"
                                                    autoComplete="date_first_pay"
                                                    className="block  w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange || ""}
                                                    value={getuser.date_first_pay}
                                                />
                                            </dd>
                                        </div>
                                    </dl>
                                    <dl className="divide-y divide-gray-100">
                                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-900">
                                                Pay Rate <span className="text-[#d50000]">*</span>
                                            </dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                <select
                                                    id="job_pay_type"
                                                    name="job_pay_type"
                                                    autoComplete="job_pay_type"
                                                    className="block w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange || ""}
                                                >
                                                    {getuser.job_pay_type === "annual" && (
                                                        <>
                                                            <option value="annual" selected>Annual</option>
                                                            <option value="monthly">Monthly</option>
                                                            <option value="hourly">Hourly</option>
                                                        </>
                                                    )}
                                                    {getuser.job_pay_type === "monthly" && (
                                                        <>
                                                        <option value="annual">Annual</option>
                                                        <option value="monthly" selected>Monthly</option>
                                                        <option value="hourly">Hourly</option>
                                                        </>
                                                    )}
                                                    {getuser.job_pay_type === "hourly" && (
                                                        <>
                                                            <option value="annual">Annual</option>
                                                            <option value="monthly">Monthly</option>
                                                            <option value="hourly" selected>Hourly</option>
                                                        </>
                                                    )}
                                                    {getuser.job_pay_type === null && (
                                                        <>
                                                            <option value="annual">Annual</option>
                                                            <option value="monthly">Monthly</option>
                                                            <option value="hourly">Hourly</option>
                                                        </>
                                                    )}
                                                </select>
                                            </dd>
                                        </div>
                                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-900">
                                                Joining Date <span className="text-[#d50000]">*</span>
                                            </dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                <input
                                                    type="date"
                                                    name="joining_date"
                                                    autoComplete="joining_date"
                                                    className="block  w-[350px] rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange || ""}
                                                    value={getuser.joining_date}
                                                />
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </>
                        )}
                        <div className="my-3 lg:pr-12 pr-4 flex items-center justify-end gap-x-6">
                            <button type="submit" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]" style={{marginBottom: "20px"}}>Save Changes<span className="ml-2 mt-1"><IoIosSave /></span></button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </>
    );
}
export default EditProfileData;