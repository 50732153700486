import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Prof from "../profile.webp";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosSave } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

function getBase64(file) {
    return new Promise(function(resolve, reject) {
      const reader = new FileReader();
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
}

function UploadProfileData() {
    const [getuser, setGetuser] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [form, setForm] = useState([]);

    const handleFile = async (e) => {
        const fileFound = e.target.type === 'file' && e.target.files[0];

        if (fileFound) {
        try {
            const result = await getBase64(fileFound);
            setForm([
            ...form,
            {
                UUID_Answer: 'image_name',
                Answer: '',
                Document: true,
                Document_Upload: result,
            },
            ]);
        } catch (error) {
            console.error('Error converting file to base64:', error);
        }
        }
    };

  const handleUpload = (e) => {
    e.preventDefault();

    const submitData = {
      UUID_Formulier: '117F994F-F803-7249-91E9-EE1E7B691DFF',
      answers: form,
      id: id,
    };

    axios.post('https://projects.bzbeetech.com/busybeetech/api/image', submitData)
      .then((response) => {
        if(response.data.status === "success"){
            toast.success(response.data.message);
            setTimeout(() => {
                navigate("/profile");
            }, 1500);
        }else{
            toast.error("Error uploading file");
        }
      })
      .catch((error) => {
        if (error.isAxiosError && !error.response) {
            toast.error("Network is not stable");    
        } else {
            console.error('API Error:', error.response.data);
            toast.error("An unexpected error occurred while processing the request.");
        }
      });
    };
    //Get Job
    useEffect(() => {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/profile/${id}`).then((response) => {
            setGetuser(response.data.employee);
            
            const ProfileImage = response.data.employee.image 
            ? `https://projects.bzbeetech.com/busybeetech/public/upload/${response.data.employee.image}`
            : process.env.PUBLIC_URL + '/profile.webp';
            setSelectedFile(ProfileImage)
        });
    }, [id]);

    // profile upload start
    const [isHovered, setIsHovered] = useState(false);
    const [selectedFile, setSelectedFile] = useState(Prof);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        document.getElementById("fileInput").click();
    };

    return (
        <>
            <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <Link to="/profile" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                Profile
                            </Link>
                        </div>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Upload Profile</span>
                        </div>
                    </li>
                </ol>
                <Link
                    to="/profile"
                    type="button"
                    className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                >
                    <span className=" mt-1">
                        <IoMdArrowRoundBack />
                    </span>{" "}
                    <span className="pl-2">Back</span>
                </Link>
            </nav>
            <div className="flex lg:flex-row md:flex-row flex-col justify-center">
                <div className="lg:w-[35%] md:w-[35%]  w-full h-[35%] shadow rounded-lg">
                    <div className=" w-full py-8 flex flex-col  items-center ">
                        <form onSubmit={handleUpload} encType="multipart/form-data">
                            <div className="">
                                <div style={{width: "210px",height: "210px", borderRadius: "50%",position: "relative",overflow: "hidden",
                                cursor: "pointer",}}
                                    onMouseEnter={handleMouseEnter}onMouseLeave={handleMouseLeave}onClick={handleClick}>

                                        <input type="file" id="fileInput" name="image" className="hidden" onChange={handleFile} />
                                    {isHovered && (
                                        <div style={{position: "absolute",top: "0",left: "0",width: "210px",height: "210px",display: "flex",
                                        justifyContent: "center",alignItems: "center",backgroundColor: "rgba(0, 0, 0, 0.5)",color: "#fff",
                                        fontSize: "18px",}}>
                                            Click to Upload
                                        </div>
                                    )}
                                    {selectedFile && (
                                        <img src={selectedFile} alt="Selected Preview"style={{width: "100%",height: "auto",objectFit: "cover",
                                        borderRadius: "50%",}}className="w-[150px] m-auto"/>
                                    )}
                                </div>
                            </div>
                            <div className="my-3 lg:pr-12 pr-4 flex items-center justify-end gap-x-6">
                                <button type="submit" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm
                                 hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                                 focus-visible:outline-[#ffae37]">
                                    Update Profile
                                    <span className="ml-2 mt-1">
                                        <IoIosSave />
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default UploadProfileData;