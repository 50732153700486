import { React, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../logo-main.png";

export default function Login() {
    const navigate = useNavigate();

    // fields onchange
    const initialFormData2 = {
        email: "",
        password: "",
    };
    const [formData2, setFormData2] = useState({ ...initialFormData2 });
    const handleInputChange2 = (e) => {
        const { name, value, type } = e.target;

        if (type === "radio") {
            setFormData2((prevFormData2) => ({
                ...prevFormData2,
                notificationMethod2: value,
            }));
        } else {
            setFormData2((prevFormData2) => ({
                ...prevFormData2,
                [name]: value,
            }));
        }
    };
    const handleSubmit2 = async (e) => {
        e.preventDefault();

        try {
            const userData = {
                email: formData2.email,
                password: formData2.password,
            };
            //
            const response = await axios.post("https://projects.bzbeetech.com/busybeetech/api/login", userData, {
                withCredentials: true,
            });

            console.log("Response Status :- " + response.data.errors);

            if (response.data.status === "success") {
                localStorage.setItem("user", JSON.stringify(response.data.user));
                navigate("/dashboard");
            } else if (response.data.status === "error") {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("API Error:", error);
            toast.error("An unexpected error occurred while processing the request.");
        }
    };

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md"></div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 pb-12 shadow sm:rounded-lg sm:px-12">
                        <form className="space-y-6" onSubmit={handleSubmit2}>
                            <img src={logo} alt="Busy Bee Technologies Logo" className="m-auto w-[130px] object-cover" />
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        name="email"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData2.email}
                                        onChange={handleInputChange2}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="password"
                                        name="password"
                                        autocomplete="password"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData2.password}
                                        onChange={handleInputChange2}
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-[#ffae37]  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#ffae37]  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37] "
                                >
                                    Sign in
                                </button>
                                <ToastContainer />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}