import { useState, useEffect } from "react";
import axios from "axios";
import { FaUserCheck } from "react-icons/fa";
import { FaUserAltSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUsers } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";

export default function Home() {
    const [getuser, setGetUser] = useState([]);
    const [getCount, setGetCount] = useState([]);
    const [getrole, setGetRole] = useState([]);
    const [getLeave, setGetLeave] = useState([]);  
    const getUserRole = () => {
        const userData = localStorage.getItem('user');
        if (!userData) return null;
        const user = JSON.parse(userData);
        setGetRole(user.role);
    };
    //Get User
    const getUsers = () => {
        try {
            axios.get(`https://projects.bzbeetech.com/busybeetech/api/user`).then((response) => {
                setGetUser(response.data.user);
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };
    //Get User Count
    const getUserCount = () => {
        try {
            axios.get(`https://projects.bzbeetech.com/busybeetech/api/user/count`).then((response) => {
                setGetCount(response.data);
            })
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };
    //Get Leave
    const getLeaveFun = () => {
        try {
            const userData = localStorage.getItem('user');
            const user = JSON.parse(userData);
            if (user && user.id) {
                const userId = user.id; 
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                };
    
                axios.get(`https://projects.bzbeetech.com/busybeetech/api/leave/${userId}`, config)
                .then((response) => {
                    setGetLeave(response.data.leave);
                });
            }
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");
            } else {
                console.error('API Error:', error.response ? error.response.data : error);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };
    
    const formatTime = (time) => {
        if (!time) return '';
        const [hour, minute] = time.split(':');
        const date = new Date();
        date.setHours(hour, minute);
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };
    const formatDateAndTime = (datetime) => {
        if (!datetime) return 'N/A';
        const date = new Date(datetime);
        const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = date.toLocaleDateString('en-US', optionsDate);
        const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
        return `${formattedDate} ${formattedTime}`;
    };
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    };
    useEffect(() => {
        getUsers();
        getUserCount();
        getUserRole();
        getLeaveFun();
    }, []);

    return (
        <>
            <div className="mt-12">
                <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
                    <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-[#ffae37]  to-yellow-300 text-white absolute mt-[10px] grid h-16 w-16 place-items-center">
                            <FaUsers />
                        </div>
                        <div className="p-4 text-right">
                            <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Total Users</p>
                            <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{getCount.user ? getCount.user : 0}</h4>
                        </div>
                    </div>
                    <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-900 to-blue-500 text-white absolute mt-[10px] grid h-16 w-16 place-items-center">
                            <FaUserFriends />
                        </div>
                        <div className="p-4 text-right">
                            <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Total Employee</p>
                            <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{getCount.employee ? getCount.employee : 0}</h4>
                        </div>
                    </div>
                    <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-900 to-green-500 text-white absolute mt-[10px] grid h-16 w-16 place-items-center">
                            <FaUserCheck />
                        </div>
                        <div className="p-4 text-right">
                            <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Active Users</p>
                            <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{getCount.active ? getCount.active : 0}</h4>
                        </div>
                    </div>
                    <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-red-600 to-red-400 text-white absolute mt-[10px] grid h-16 w-16 place-items-center">
                            <FaUserAltSlash />
                        </div>
                        <div className="p-4 text-right">
                            <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">InActive Users</p>
                            <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{getCount.inactive ? getCount.inactive : 0}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto lg:overflow-x-visible sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full  align-middle sm:px-6 lg:px-8">
                        {getrole === 'Super Admin' ?(
                            <div className="">
                                <table className="min-w-full divide-y divide-gray-300 table-style" key="theads">
                                    <thead className="bg-gray-50" key="thead">
                                        <tr key="thead">
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Name
                                            </th>

                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Email
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Role
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody key="tbody" className=" bg-white">
                                        {Array.isArray(getuser) ? (
                                            getuser.map((person) => (
                                                <>
                                                    <tr key={person.email}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{person.name} {person.last_name}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.email}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                                            <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20`}>
                                                                {person.role}
                                                            </span>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            <span
                                                                className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${
                                                                    person.status == 1 ? "text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 " : "text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20"
                                                                }`}
                                                            >
                                                                {person.status == 1 ? (
                                                                    <>
                                                                        <FaUserCheck /> <h4 className="ml-2">Active</h4>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <FaUserAltSlash /> <h4 className="ml-2">Inactive</h4>
                                                                    </>
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                        ) : (
                                            <tr key="body">
                                                <td colSpan="4">No Record Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="">
                                <table className="min-w-full divide-y divide-gray-300 table-style" key="theads">
                                    <thead className="bg-gray-50" key="thead">
                                        <tr key="thead">
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Leave Type
                                            </th>

                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Date & Time
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody key="tbody" className=" bg-white">
                                        {Array.isArray(getLeave) ? (
                                            getLeave.map((person) => (
                                                <>
                                                    <tr key={person.id}>
                                                        <td className="whitespace-nowrap py-2 pl-4 pr-3 capitalize text-sm text-gray-900 sm:pl-6">
                                                            <span className={`this_span inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20`}>
                                                                {person.leave_type || 'N/A'}
                                                            </span>
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                            {
                                                                person.leave_type === 'half' ? (
                                                                    <>
                                                                    {formatDateAndTime(person.half_day)}
                                                                    </>
                                                                ) : person.leave_type === 'full' ? (
                                                                    <>
                                                                        {formatDate(person.full_day)}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    {formatTime(person.short_leave_to)} - {formatTime(person.short_leave_from)}
                                                                    </>
                                                                )
                                                            }
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            <span
                                                                className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${
                                                                    person.status == 1 ? "text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 " : "text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20"
                                                                }`}
                                                            >
                                                                {person.status == 1 ? (
                                                                    <>
                                                                        <FaUserCheck /> <h4 className="ml-2">Accepted</h4>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <FaUserAltSlash /> <h4 className="ml-2">Rejected</h4>
                                                                    </>
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                        ) : (
                                            <tr key="body">
                                                <td colSpan="4" className="text-center">No Record Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        </>
    );
}