import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { MdEditDocument } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
const permissions = [
    { name: "view_employee", label: "View Employee" },
    { name: "post_job", label: "Post Job" },
    { name: "view_applicants", label: "View Applicants" },
    { name: "meeting_schedule", label: "Meeting Schedule" },
    { name: "edit_employee", label: "Edit Employee" },
    { name: "update_profile", label: "Update Profile" },
];

const EditRole = () => {
    const [editRole, setEditRole] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(true);

    
    const initialFormData = {
        role: '',
        permissions: {},
    };
    const [formData, setFormData] = useState({
        role: '',
        status: '',
        permissions: {
            "view_employee": false,
            "post_job": false,
            "view_applicants": false,
            "meeting_schedule": false,
            "edit_employee": false,
            "update_profile": false,
        },
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                permissions: {
                    ...prevFormData.permissions,
                    [name]: checked ?1 :0,
                },
            }));
        } else if (type === "radio" && name === "status") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                status: value,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    function updatePost(e) {
        e.preventDefault();
        try {
            axios
                .put(`https://projects.bzbeetech.com/busybeetech/api/role/${id}`, {
                    role: editRole.role,
                    permissions: formData.permissions,
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            navigate("/role");
                        }, 1500);
                    } else if (response.data.errors) {
                        Object.entries(response.data.errors).forEach(([field, messages]) => {
                            messages.forEach((message) => {
                                toast.error(`${field}: ${message}`);
                            });
                        });
                    }
                });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                // Display an alert or perform any other error handling here
                toast.error("Network is not stable");    
            } else {
                // Non-network error (e.g., the server returns an error status)
                console.error('API Error:', error.response.data);
                // Display an alert or perform any other error handling here
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    }

    //Get Job
    useEffect(() => {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/role/${id}/edit `).then((response) => {
            setEditRole(response.data.role || {});
        });
    }, [id]);

    return (
        <>
            <form onSubmit={updatePost}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                <li className="inline-flex items-center">
                                    <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <Link to="/role" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                            Role
                                        </Link>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Edit Role</span>
                                    </div>
                                </li>
                            </ol>
                            <Link
                                to="/role"
                                type="button"
                                className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                            >
                                <span className=" mt-1">
                                    <IoMdArrowRoundBack />
                                </span>{" "}
                                <span className="pl-2">Back</span>
                            </Link>
                        </nav>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Role <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="role"
                                        autocomplete="role"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editRole.role || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <h1 className="font-bold text-2xl text-gray-600 mt-5">Permissions:</h1>
                        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-full">
                                <div className="flex">
                                {permissions.map(permission => (
                                    <div key={permission.name} className="flex items-center ml-2">
                                            <input
                                                id={permission.name}
                                                name={permission.name}
                                                type="checkbox"
                                                checked={formData.permissions[permission.name]}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                            <label htmlFor={permission.name} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                {permission.label}
                                            </label>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="submit"
                        className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                    >
                        Update
                        <span className="ml-2 mt-1">
                            <MdEditDocument />
                        </span>
                    </button>
                </div>
            </form>
            <ToastContainer />
        </>
    );
};

export default EditRole;