import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { MdEditDocument } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
const EditJob_form = () => {
    const [editJob, setEditJob] = useState({});
    const [editJobQuestion, setEditJobQuestion] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    // Local storage Get Data
    var userData = localStorage.getItem('user');
    var user = JSON.parse(userData);
    var userId = user.id;
    // fields onchange
    const initialFormData = {
        position: "",
        job_category: "",
        vacancy: "",
        experience: "",
        post_date: "",
        expire_date: "",
        salary_from: "",
        salary_to: "",
        city: "",
        education: "",
        description: "",
        notificationMethod: "1",
    };
    const [formData, setFormData] = useState({ ...initialFormData });
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        if (type === "radio") {
            setEditJob((prevFormData) => ({
                ...prevFormData,
                notificationMethod: value,
            }));
        } else {
            setEditJob((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };
    function updatePost(e) {
        e.preventDefault();
        try {
            axios.put(`https://projects.bzbeetech.com/busybeetech/api/job/${id}`, {
                    user_id: userId,
                    position: editJob.position,
                    job_category: editJob.job_category,
                    vacancy: editJob.vacancy,
                    experience: editJob.experience,
                    post_date: editJob.post_date,
                    expire_date: editJob.expire_date,
                    salary_from: editJob.salary_from,
                    salary_to: editJob.salary_to,
                    city: editJob.city,
                    education: editJob.education,
                    description: editJob.description,
                    question: editJobQuestion.map((q) => q.question),
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            navigate("/job");
                        }, 1500);
                    } else if (response.data.errors) {
                        Object.entries(response.data.errors).forEach(([field, messages]) => {
                            messages.forEach((message) => {
                                toast.error(`${field}: ${message}`);
                            });
                        });
                    }
                });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    }
    const handleQuestionChange = (index, value) => {
        setEditJobQuestion((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index].question = value;
            return updatedQuestions;
        });
    };
    //Get Job
    useEffect(() => {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/job/${id}/edit `).then((response) => {
            setEditJob(response.data.job || {});
            setEditJobQuestion(response.data.question || {});
        });
    }, [id]);
    return (
        <>
            <form onSubmit={updatePost}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                <li className="inline-flex items-center">
                                    <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <Link to="/job" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                            Job
                                        </Link>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Edit Job</span>
                                    </div>
                                </li>
                            </ol>
                            <Link
                                to="/job"
                                type="button"
                                className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                            >
                                <span className=" mt-1">
                                    <IoMdArrowRoundBack />
                                </span>{" "}
                                <span className="pl-2">Back</span>
                            </Link>
                        </nav>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Position <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="position"
                                        autocomplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.position || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Job Category <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="category"
                                        name="job_category"
                                        autocomplete="category"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        onChange={handleInputChange}
                                    >
                                        {editJob.job_category === "senior" && (
                                            <>
                                                <option selected value="senior">
                                                    Senior
                                                </option>
                                                <option value="mid_level">Mid Level</option>
                                                <option value="junior">Junior</option>
                                                <option value="internee">Internee</option>
                                            </>
                                        )}
                                        {editJob.job_category === "mid_level" && (
                                            <>
                                                <option value="senior">Senior</option>
                                                <option value="mid_level" selected>
                                                    Mid Level
                                                </option>
                                                <option value="junior">Junior</option>
                                                <option value="internee">Internee</option>
                                            </>
                                        )}
                                        {editJob.job_category === "junior" && (
                                            <>
                                                <option value="senior">Senior</option>
                                                <option value="mid_level">Mid Level</option>
                                                <option value="junior" selected>
                                                    Junior
                                                </option>
                                                <option value="internee">Internee</option>
                                            </>
                                        )}
                                        {editJob.job_category === "internee" && (
                                            <>
                                                <option value="senior">Senior</option>
                                                <option value="mid_level">Mid Level</option>
                                                <option value="junior">Junior</option>
                                                <option value="internee" selected>
                                                    Internee
                                                </option>
                                            </>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    No. of Vacancy
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="number"
                                        name="vacancy"
                                        autocomplete="vacancy"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.vacancy || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Experience <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="experience"
                                        autocomplete="experience"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.experience || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Posted Date <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="post_date"
                                        autocomplete="post_date"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.post_date || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Close Date
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="expire_date"
                                        autocomplete="expire_date"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.expire_date || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Salary From <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="number"
                                        name="salary_from"
                                        autocomplete="salary_from"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.salary_from || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Salary To
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="number"
                                        name="salary_to"
                                        autocomplete="salary_to"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.salary_to || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    City <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="city"
                                        autocomplete="city"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.city || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Education <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="education"
                                        autocomplete="education"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.education || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-full">
                                {Array.isArray(editJobQuestion) && editJobQuestion.map((q, index) => (
                                    
                                    <div key={index}>
                                        
                                        <label htmlFor="last-name" className="mb-2 block text-sm font-medium leading-6 text-gray-900">
                                            Question {index+1}
                                        </label>
                                        <textarea
                                            type="text"
                                            name={`question[${index}]`}
                                            autoComplete={`question-${index}`}
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={q.question || ""}
                                            onChange={(e) => handleQuestionChange(index, e.target.value)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="sm:col-span-full">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Description <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        type="text"
                                        name="description"
                                        autocomplete="description"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={editJob.description || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="submit"
                        className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                    >
                        Update
                        <span className="ml-2 mt-1">
                            <MdEditDocument />
                        </span>
                    </button>
                </div>
            </form>
            <ToastContainer />
        </>
    );
};
export default EditJob_form;