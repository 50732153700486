import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaPlusCircle } from "react-icons/fa";
import { Fragment , useRef} from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { FaUserCheck } from "react-icons/fa";
import { FaUserAltSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TiTimes } from "react-icons/ti";
import { FaCheckDouble } from "react-icons/fa6";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function RoleList() {
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [formError, setFormError] = useState(false);
    const [setRole, setGetRole] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    
    const [value, setValue] = useState('');

    const { id } = useParams();
   
    const navigate = useNavigate();
    
    const handleEditClick = (person) => {
        setSelectedPerson(person);
        setEdit(true);
        navigate("/roleedit"); // Redirect to the edit form page
    };

    //Get Role
    const setRoles = () => {
        try {

            axios.get(`https://projects.bzbeetech.com/busybeetech/api/role`).then((response) => {
                setGetRole(response.data.role);
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                // Display an alert or perform any other error handling here
                toast.error("Network is not stable");    
            } else {
                // Non-network error (e.g., the server returns an error status)
                console.error('API Error:', error.response.data);
                // Display an alert or perform any other error handling here
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
        
    }

    //Update Status
    const updateStatus = (id) => {
        try {

            axios.get(`https://projects.bzbeetech.com/busybeetech/api/role/status/${id}`).then((response) => {
                if (response.data.status === "success") {
                    console.log("data "+ response.data)
                    toast.success(response.data.message);
                    setRoles()
                }else {
                    toast.error(response.data.message);
                }
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                // Display an alert or perform any other error handling here
                toast.error("Network is not stable");    
            } else {
                // Non-network error (e.g., the server returns an error status)
                console.error('API Error:', error.response.data);
                // Display an alert or perform any other error handling here
                toast.error("An unexpected error occurred while processing the request.");
            }
        }

    }

    const cancelButtonRef = useRef(null)

     // Delete Role
     const handleDelete = async  (value) => {
        try {
            const response  =await axios.delete(`https://projects.bzbeetech.com/busybeetech/api/role/${value}`);
            if (response.data.status === "success") {
                toast.success(response.data.message);
                setRoles();
            }
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                // Display an alert or perform any other error handling here
                toast.error("Network is not stable");    
            } else {
                // Non-network error (e.g., the server returns an error status)
                console.error('API Error:', error.response.data);
                // Display an alert or perform any other error handling here
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };

    useEffect(() => {
       setRoles()
    }, []);

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li className="inline-flex items-center">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                Dashboard
                            </Link>
                        </li>
                        <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Role</span>
                        </div>
                        </li>
                    </ol>
                    <Link to="/addrole" type="button" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                        <span>Create</span><span className="pl-2 mt-1"><FaPlusCircle /></span> 
                    </Link>
                </nav>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto lg:overflow-x-visible sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                            <div className="">
                                <table className="min-w-full">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Role
                                            </th>
                                            <th scope="col" colSpan="6" className=" text-center px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Permissions
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {Array.isArray(setRole) ? (
                                            setRole.map((person) => (
                                            <>
                                            <tr key={person.email}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm">{person.role}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${person.view_employee == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ' : 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20'}`}>
                                                        {person.view_employee == 1 ? (
                                                            <>
                                                                <FaCheckDouble  /> <h4 className="ml-2">View Employee</h4>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TiTimes  /> <h4 className="ml-2">View Employee</h4>
                                                            </>
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${person.edit_employee == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ' : 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20'}`}>
                                                        {person.edit_employee == 1 ? (
                                                            <>
                                                                <FaCheckDouble  /> <h4 className="ml-2">Edit Employee</h4>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TiTimes  /> <h4 className="ml-2">Edit Employee</h4>
                                                            </>
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${person.post_job == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ' : 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20'}`}>
                                                        {person.post_job == 1 ? (
                                                            <>
                                                                <FaCheckDouble  /> <h4 className="ml-2">Post Job</h4>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TiTimes  /> <h4 className="ml-2">Post Job</h4>
                                                            </>
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${person.view_applicant == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ' : 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20'}`}>
                                                        {person.view_applicant == 1 ? (
                                                            <>
                                                                <FaCheckDouble  /> <h4 className="ml-2">View Applicant</h4>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TiTimes  /> <h4 className="ml-2">View Applicant</h4>
                                                            </>
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${person.meeting_schedule == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ' : 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20'}`}>
                                                        {person.meeting_schedule == 1 ? (
                                                            <>
                                                                <FaCheckDouble  /> <h4 className="ml-2">Meeting Schedule</h4>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TiTimes  /> <h4 className="ml-2">Meeting Schedule</h4>
                                                            </>
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${person.update_profile == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ' : 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20'}`}>
                                                        {person.update_profile == 1 ? (
                                                            <>
                                                                <FaCheckDouble  /> <h4 className="ml-2">Update Profile</h4>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TiTimes  /> <h4 className="ml-2">Update Profile</h4>
                                                            </>
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${person.status == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ' : 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20'}`}>
                                                                {person.status == 1 ? (
                                                                    <>
                                                                        <FaUserCheck /> <h4 className="ml-2">Active</h4>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <FaUserAltSlash /> <h4 className="ml-2">Inactive</h4>
                                                                    </>
                                                                )}
                                                            </span>

                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <div className="flex">
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button className="flex items-center  ">
                                                                        <span className="sr-only">Open options</span>
                                                                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </Menu.Button>
                                                                </div>

                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-[120px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="py-3 px-3 ">
                                                                            <Menu.Item >
                                                                                <Link to={`/roleedit/${person.id}`} style={{ marginLeft: '0.75rem !important' }} className="flex flex-row justify-between text-green-600 hover:text-green-900" onClick={() => handleEditClick(person)}>
                                                                                    <CiEdit />
                                                                                    <h4>Edit</h4>
                                                                                </Link>
                                                                                
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                    <Link className="flex flex-row justify-between text-red-600 hover:text-red-900 " onClick={() => {setValue(person.id);handleDelete(person.id);}}>

                                                                                        <RiDeleteBin6Line />
                                                                                        <h4>Delete</h4>
                                                                                    </Link>
                                                                                </Menu.Item>

                                                                            <Menu.Item>
                                                                                    <Link className={`flex flex-row justify-between ${person.status == 1 ? 'text-red-600 hover:text-red-900' : 'text-green-600 hover:text-green-900'}`} onClick={() => updateStatus(person.id)}>
                                                                                        {person.status == 1 ? (
                                                                                            <>
                                                                                                <FaUserAltSlash className="mt-1" /> <h4>Inactive</h4>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <FaUserCheck className="mt-1" /> <h4>Active</h4>
                                                                                            </>
                                                                                        )}
                                                                                    </Link>
                                                                                </Menu.Item>

                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                            
                                                        </div>
                                                </td>
    
                                            </tr>
                                                </>
                                            ))
                                        ) 
                                        : (
                                            <tr>
                                                <td colSpan="4" className="text-center">No Record Found</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        </>
        
    );
}
