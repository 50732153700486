import { React, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosSave } from "react-icons/io";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaPlusCircle , FaMinusCircle } from "react-icons/fa";
const status = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
];
const question_status = [
    { id: "1", title: "Question Show" },
    { id: "0", title: "Question Hide" },
];
const AddJob_form = () => {
    const [questions, setQuestions] = useState(['']);
    const navigate = useNavigate();
    // Local storage Get Data
    var userData = localStorage.getItem('user');
    var user = JSON.parse(userData);
    var userId = user.id;
    // fields onchange
    const initialFormData = {
        position: "",
        job_category: "",
        vacancy: "",
        experience: "",
        post_date: "",
        expire_date: "",
        salary_from: "",
        salary_to: "",
        city: "",
        education: "",
        description: "",
        status: "1",
    };
    const [formData, setFormData] = useState({ ...initialFormData });
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        if (type === "radio" && name === "status" && name === "question_status") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                status: value,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };
    //POST request
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userData = {
                user_id: userId,
                position: formData.position,
                job_category: formData.job_category,
                vacancy: formData.vacancy,
                experience: formData.experience,
                post_date: formData.post_date,
                expire_date: formData.expire_date,
                salary_from: formData.salary_from,
                salary_to: formData.salary_to,
                city: formData.city,
                education: formData.education,
                status: formData.status,
                description: formData.description,
                question: questions,
                question_status: formData.question_status,
            };
            const response = await axios.post("https://projects.bzbeetech.com/busybeetech/api/job", userData);
            if (response.data.status === "success") {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate("/job");
                }, 1500);
            } else if (response.data.errors) {
                Object.entries(response.data.errors).forEach(([field, messages]) => {
                    messages.forEach((message) => {
                        toast.error(`${field}: ${message}`);
                    });
                });
            }
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };
    const handleAddQuestion = () => {
        setQuestions([...questions, '']);
    };
    const handleRemoveQuestion = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
    };
    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index] = value;
        setQuestions(updatedQuestions);
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                <li className="inline-flex items-center">
                                    <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <Link to="/job" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                            Job
                                        </Link>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Create Job</span>
                                    </div>
                                </li>
                            </ol>
                            <Link to="/job" type="button" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm 
                                font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline 
                                focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                                <span className=" mt-1"><IoMdArrowRoundBack /></span>
                                <span className="pl-2">Back</span>
                            </Link>
                        </nav>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Position <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="position"
                                        autocomplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.position}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Job Category <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="category"
                                        name="job_category"
                                        autocomplete="category"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.job_category}
                                        onChange={handleInputChange}
                                    >
                                        <option>Select Job Category</option>
                                        <option value="senior">Senior</option>
                                        <option value="mid_level">Mid-Level</option>
                                        <option value="junior">Junior</option>
                                        <option value="internee">Internee</option>
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    No. of Vacancy
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="number"
                                        name="vacancy"
                                        autocomplete="vacancy"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.vacancy}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Experience <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="experience"
                                        autocomplete="experience"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.experience}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Posted Date <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="post_date"
                                        autocomplete="post_date"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.post_date}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Close Date
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="expire_date"
                                        autocomplete="expire_date"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.expire_date}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Salary From <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="number"
                                        name="salary_from"
                                        autocomplete="salary_from"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.salary_from}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Salary To
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="number"
                                        name="salary_to"
                                        autocomplete="salary_to"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.salary_to}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    City <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="city"
                                        autocomplete="city"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Education <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="education"
                                        autocomplete="education"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.education}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-full">
                                {questions.map((question, index) => (
                                    <div key={index} className="sm:col-span-full">
                                        <label htmlFor={`question-${index}`} className="block text-sm font-medium leading-6 text-gray-900">
                                            Question <span className="text-[#d50000]">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                id={`question-${index}`}
                                                type="text"
                                                name={`question[${index}]`}
                                                autoComplete="question"
                                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                value={questions.question}
                                                onChange={(e) => handleQuestionChange(index, e.target.value)}
                                            />
                                        </div>
                                        {index > 0 && (
                                            <div className="flex">
                                                <div onClick={() => handleRemoveQuestion(index)} className="mt-2 w-[32px] text-white bg-red-500 p-2 rounded-2xl">
                                                    <FaMinusCircle />
                                                </div>
                                                {index === questions.length - 1 && (
                                                    <div onClick={handleAddQuestion} className="mt-2 ml-2 w-[32px] text-white bg-[#ffae37] p-2 rounded-2xl">
                                                        <FaPlusCircle />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {questions.length === 1 && (
                                    <div>
                                        <div onClick={handleAddQuestion} className="mt-2 w-[32px] text-white bg-[#ffae37] p-2 rounded-2xl">
                                            <FaPlusCircle />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="sm:col-span-full">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Description <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        type="text"
                                        name="description"
                                        autocomplete="description"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                   Question Status <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <div>
                                        <fieldset className="mt-4">
                                            <legend className="sr-only">Notification method</legend>
                                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                                {question_status.map((question_status) => (
                                                    <div key={question_status.id} className="flex items-center">
                                                        <input
                                                            id={question_status.id}
                                                            name="question_status"
                                                            type="radio"
                                                            value={question_status.id}
                                                            checked={formData.question_status === question_status.id}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />

                                                        <label htmlFor={question_status.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                            {question_status.title}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Status <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <div>
                                        <fieldset className="mt-4">
                                            <legend className="sr-only">Notification method</legend>
                                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                                {status.map((status) => (
                                                    <div key={status.id} className="flex items-center">
                                                        <input
                                                            id={status.id}
                                                            name="status" // Update this line
                                                            type="radio"
                                                            value={status.id}
                                                            checked={formData.status === status.id}
                                                            onChange={handleInputChange}
                                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />

                                                        <label htmlFor={status.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                            {status.title}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="submit" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold 
                    text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 
                    focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                        Save <span className="ml-2 mt-1"><IoIosSave /></span>
                    </button>
                </div>
            </form>
            <ToastContainer />
        </>
    );
};
export default AddJob_form;