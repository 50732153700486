import DesktopSidebar from "../Components/DesktopSidebar";
import AddJob_form from "../Components/AddJob_form";

export default function Addjob() {
    return (
        <>
            <div>
                <DesktopSidebar />
                <div className="lg:pl-72">
                    <main className="py-10">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <AddJob_form />
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
