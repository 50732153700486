import { useState, useEffect ,Fragment , useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { FaPlusCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { FaPhoneVolume , FaPhoneSlash  } from "react-icons/fa6";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { BiSolidFilePdf } from "react-icons/bi";


export default function MeetingSchedule() {

    const [setApplicant, setGetApplicant] = useState([]);
    const [setApplicantJob, setGetApplicantJob] = useState([]);
    //Get Applicant
    const setApplicants = () => {
        try {
            axios.get(`https://projects.bzbeetech.com/busybeetech/api/meeting`).then((response) => {
                setGetApplicant(response.data.applicant);
                setGetApplicantJob(response.data.job);
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }  
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const ampm = date.getHours() >= 12 ? "PM" : "AM";
      
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
      };
      
    //Update Applicant Call Status
    const updateStatus = (id) => {
        try {
            axios.get(`https://projects.bzbeetech.com/busybeetech/api/meeting/${id}`).then((response) => {
                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    setApplicants()
                }else {
                    toast.error(response.data.message);
                }
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    }

    const reportTemplateRef = useRef(null);

    //PDF  Download
    const handleGeneratePdf = () => {
        const doc = new jsPDF({
          orientation: "p",
          unit: "pt",
          format: "a3",
        });
      
        const pdfOptions = {
          windowWidth: 1400,
          x: 10,
          y: 10,
          html2canvas: { scale: 0.57 },
          width: 900,
        };
      
        const pdfTableRef = document.createElement("div");
        pdfTableRef.className = "pdf-table-wrapper";
        pdfTableRef.innerHTML = reportTemplateRef.current.innerHTML;
      
        // Find the table with the class "last_table"
        const lastTable = pdfTableRef.querySelector(".last_table");
      
        // If the table exists
        if (lastTable) {
          // Remove the last thead th
          const lastTheadTh = lastTable.querySelector("thead tr th:last-child");
          if (lastTheadTh) {
            lastTheadTh.remove();
          }
      
          // Remove the last tbody td in each tr
          const pdfActionColumns = lastTable.querySelectorAll("tbody tr td:last-child");
      
          pdfActionColumns.forEach((column) => {
            if (column) {
              column.remove();
            }
          });
      
          // Modify the action column for the PDF
          const pdfActionColumnsSpans = lastTable.querySelectorAll("tbody tr td span.this_span");
      
          pdfActionColumnsSpans.forEach((span) => {
            if (span) {
              span.classList.remove(
                "text-green-600",
                "bg-green-50",
                "hover:text-green-900",
                "ring-green-600/20",
                "ring-1",
                "ring-inset"
              );
              span.style.color = "black";
            }
          });
        }
      
        const tableHtml = pdfTableRef.innerHTML;
        doc.html(tableHtml, {
          ...pdfOptions,
          async callback(doc) {
            await doc.save("document");
          },
        });
    };

    //Excel Sheet Download

    const downloadExcel = () => {
        const cleanData = (obj, fieldsToRemove) => {
          const cleanedObj = { ...obj };
          fieldsToRemove.forEach((field) => delete cleanedObj[field]);
          return cleanedObj;
        };
      
        // Fields to remove
        const fieldsToRemove = [
          "id",
          "user_id",
          "applicant_id",
          "job_id",
          "created_at",
          "normal_cv",
          "dropbox_cv",
          "drive_cv",
          "job_category",
          "vacancy",
          "experience",
          "expire_date",
          "salary_from",
          "salary_to",
          "city",
          "education",
          "question_status",
          "description",
          "section",
          "call_now",
          "post_date",
          "status",
        ];
      
        // Fields to replace
        const fieldsToReplace = {
          first_name: "First Name",
          last_name: "Last Name",
          email: "Email",
          position: "Position",
          phone: "Phone",
          date_time: "Date & Time",
          interview_type: "Interview Type",
          meeting_description: "Description",
          updated_at: "Call Status",
        };
      
        const combinedData = [];
      
        if (Array.isArray(setApplicantJob) && Array.isArray(setApplicant)) {
          for (let i = 0; i < setApplicantJob.length; i++) {
            const cleanedJob = cleanData(setApplicantJob[i], fieldsToRemove);
            const cleanedApplicant = cleanData(setApplicant[i], fieldsToRemove);
            const cleanedMeeting = cleanData(setApplicant[i], fieldsToRemove);
      
            const combinedRow = {
              ...cleanedJob,
              ...cleanedApplicant,
              ...cleanedMeeting,
            };
      
            // Replace headers and format date_time
            const replacedRow = {};
            Object.keys(combinedRow).forEach((key) => {
              const replacedKey = fieldsToReplace[key] || key;
              if (replacedKey === "date_time") {
                replacedRow[replacedKey] = formatDate(combinedRow[key]);
              } else {
                replacedRow[replacedKey] = combinedRow[key];
              }
            });
      
            combinedData.push(replacedRow);
          }
        }
      
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(combinedData);
        XLSX.utils.book_append_sheet(wb, ws, "Combined Data");
        XLSX.writeFile(wb, "Export.xlsx");
    };
      

    useEffect(() => {
        setApplicants()
    }, []);

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li className="inline-flex items-center">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                Dashboard
                            </Link>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                                </svg>
                                <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Meeting Schedule</span>
                            </div>
                        </li>
                    </ol>
                </nav>
                
                <div className="bg-yellow-500 flex justify-center items-center p-2 rounded" style={{width:"150px"}}>
                    <div className="space-x-2">
                        <button onClick={handleGeneratePdf} className="p-2 bg-white rounded shadow hover:bg-gray-100 transition-colors duration-150">
                            <BiSolidFilePdf className="text-red-800" size={20} />
                        </button>

                        <button onClick={downloadExcel} className="p-2 bg-white rounded shadow hover:bg-gray-100 transition-colors duration-150">
                            <PiMicrosoftExcelLogoFill className="text-green-600" size={20} />
                        </button>
                    </div>
                </div>
                
                <div className="mt-8 flow-root" ref={reportTemplateRef} id="content">
                    <div className="-mx-4 -my-2 overflow-x-auto lg:overflow-x-visible sm:-mx-6 lg:-mx-8">
                        <div className="flex min-w-full align-middle sm:px-6 lg:px-8">
                            <div className="flex-initial w-1/5">
                                <table className=" table-style">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Job Title</th>
                                    </tr>
                                </thead>
                                <tbody className=" bg-white">
                                    {Array.isArray(setApplicantJob) ? (
                                    setApplicantJob.map((job) => (
                                        <tr key={job.position}>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm" style={{height:'57.5px'}}>{job.position}</td>
                                        </tr>
                                    ))
                                    ) : (
                                    <tr>
                                        <td colSpan="1" className="text-center"></td>
                                    </tr>
                                    )}
                                </tbody>
                                </table>
                            </div>

                            <div className="flex-auto w-4/5">
                                <table className="table-style last_table">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
                                            <th scope="col" className="text-left px-3 py-3.5 text-sm font-semibold text-gray-900">Email</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Phone</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className=" bg-white">
                                        {Array.isArray(setApplicant) ? (
                                            setApplicant.map((person) => (
                                                <>
                                                    <tr key={person.email}>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm" style={{height:'57px'}}>{person.first_name} {person.last_name}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm" style={{height:'57px'}}>{person.email}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm" style={{height:'57px'}}>{person.phone}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500" style={{height:'57px'}}>
                                                            <span className={`this_span inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset 
                                                                ${person.call_now == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20' : 
                                                                    (person.call_now == 0 ? 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20' : 
                                                                        'text-yellow-600 bg-yellow-50 hover:text-yellow-900 ring-yellow-600/20')}`}>
                                                                {person.call_now == 1 && (
                                                                    <>
                                                                        <FaPhoneVolume   className="mt-1 mr-2" /> <h4>Called {formatDate(person.updated_at)}</h4>
                                                                    </>
                                                                )}
                                                                {person.call_now == 0 && (
                                                                    <>
                                                                        <FaPhoneSlash  className="mt-1 mr-2" /> <h4>Not Called</h4>
                                                                    </>
                                                                )}
                                                            </span>
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <div className="flex">
                                                                <Menu as="div" className="relative inline-block text-left">
                                                                    <div>
                                                                        <Menu.Button className="flex items-center  ">
                                                                            <span className="sr-only">Open options</span>
                                                                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </Menu.Button>
                                                                    </div>
                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95">
                                                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-[120px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                            <div className="py-3 px-3 ">
                                                                                <Menu.Item >
                                                                                    <Link to={`/create/schedule/${person.id}`} style={{ marginLeft: '0.75rem !important' }} className="flex flex-row justify-between items-center text-green-600 hover:text-green-900">
                                                                                        <FaPlusCircle />
                                                                                        <h6>Schedule</h6>
                                                                                    </Link>
                                                                                </Menu.Item>
                                                                                <Menu.Item>
                                                                                    {person.call_now == 0 ? (
                                                                                       <Link style={{ marginLeft: '0.75rem !important' }} className="flex flex-row justify-between items-center text-green-600 hover:text-green-900"  onClick={() => updateStatus(person.id)}>
                                                                                            <FaPhoneVolume  />
                                                                                            <h6>Call Now</h6>
                                                                                        </Link>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Link style={{ marginLeft: '0.75rem !important' }} className="flex flex-row justify-between items-center text-red-600 hover:text-red-900"   onClick={() => updateStatus(person.id)}>
                                                                                                <FaPhoneSlash   />
                                                                                                <h6>Called</h6>
                                                                                            </Link>
                                                                                        </>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            </div>
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </Menu>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                        ) 
                                        : (
                                            <tr key="no record">
                                                <td colSpan="5" className="text-center">No Record Found</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
}