import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEditDocument } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import axios from "axios";

const EditTime = () => {
    const [editEmployee, setEditEmployee] = useState({});
    const [roles, setRoles] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    const handleInputChange1 = (e) => {
        const { name, value } = e.target;

        if (name === "status") {
            setEditEmployee({ ...editEmployee, [name]: value });
        } else {
            setEditEmployee({ ...editEmployee, [name]: value });
        }
    };

    const updatePost = (e) => {
        e.preventDefault();

        try {
            axios
                .put(`https://projects.bzbeetech.com/busybeetech/api/time/${id}`, {
                    normal_day_from: editEmployee.normal_day_from,
                    normal_day_to: editEmployee.normal_day_to,
                    break_time_to: editEmployee.break_time_to,
                    break_time_from: editEmployee.break_time_from,
                    ramdan_day_to: editEmployee.ramdan_day_to,
                    ramdan_day_from: editEmployee.ramdan_day_from,
                    ramdan_break_time_to: editEmployee.ramdan_break_time_to,
                    ramdan_break_time_from: editEmployee.ramdan_break_time_from,
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            navigate("/time");
                        }, 1500);
                    } else if (response.data.errors) {
                        Object.entries(response.data.errors).forEach(([field, messages]) => {
                            messages.forEach((message) => {
                                toast.error(`${field}: ${message}`);
                            });
                        });
                    }
                });
        } catch (error) {
            console.errors(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://projects.bzbeetech.com/busybeetech/api/time/${id}/edit`);
                setEditEmployee(response.data.time || {});
            } catch (error) {
                console.errors(error);
            }
        };

        fetchData();
    }, [id]);

    return (
        <>
            <form onSubmit={updatePost}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                <li className="inline-flex items-center">
                                    <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <Link to="/time" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                            Time Schedule
                                        </Link>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Edit Time Schedule</span>
                                    </div>
                                </li>
                            </ol>
                            <Link
                                to="/member"
                                type="button"
                                className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                            >
                                <span className=" mt-1">
                                    <IoMdArrowRoundBack />
                                </span>{" "}
                                <span className="pl-2">Back</span>
                            </Link>
                        </nav>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Normal Day Timing (Mon-Thurs , Sat) <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <input
                                            type="time"
                                            name="normal_day_to"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={editEmployee.normal_day_to}
                                            onChange={handleInputChange1}
                                        />
                                    </div>
                                    <div className="sm:col-span-3">
                                        <input
                                            type="time"
                                            name="normal_day_from"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={editEmployee.normal_day_from}
                                            onChange={handleInputChange1}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Break Time <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <input
                                            type="time"
                                            name="break_time_to"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={editEmployee.break_time_to}
                                            onChange={handleInputChange1}
                                        />
                                    </div>
                                    <div className="sm:col-span-3">
                                        <input
                                            type="time"
                                            name="break_time_from"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={editEmployee.break_time_from}
                                            onChange={handleInputChange1}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Ramdan Timing (Mon-Thurs , Sat) <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <input
                                            type="time"
                                            name="ramdan_day_to"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={editEmployee.ramdan_day_to}
                                            onChange={handleInputChange1}
                                        />
                                    </div>
                                    <div className="sm:col-span-3">
                                        <input
                                            type="time"
                                            name="ramdan_day_from"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={editEmployee.ramdan_day_from}
                                            onChange={handleInputChange1}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Ramdan Break Time <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <input
                                            type="time"
                                            name="ramdan_break_time_to"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={editEmployee.ramdan_break_time_to}
                                            onChange={handleInputChange1}
                                        />
                                    </div>
                                    <div className="sm:col-span-3">
                                        <input
                                            type="time"
                                            name="ramdan_break_time_from"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                            value={editEmployee.ramdan_break_time_from}
                                            onChange={handleInputChange1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="submit"
                        className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]"
                    >
                        Update
                        <span className="ml-2 mt-1">
                            <MdEditDocument />
                        </span>
                    </button>
                    <ToastContainer />
                </div>
            </form>
        </>
    );
};

export default EditTime;