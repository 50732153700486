import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Fragment , useRef} from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { MdPendingActions } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheckDouble } from "react-icons/fa6";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function RequestApprove() {
    const [setRequest, setGetRequest] = useState([]);

    //Get Role
    const setRequestFun = () => {
        try {

            axios.get(`https://projects.bzbeetech.com/busybeetech/api/profile`).then((response) => {
                if (response.data.status === 'success') {
                        setGetRequest(response.data.users);
                } else {
                    console.error('API request failed:', response.data.message);
                }
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    }

    //Update Status
    const updateStatus = (id) => {
        try {

            axios.get(`https://projects.bzbeetech.com/busybeetech/api/profile/status/${id}`).then((response) => {
                if (response.data.status === "success") {
                    console.log("data "+ response.data)
                    toast.success(response.data.message);
                    setRequestFun()
                }else {
                    toast.error(response.data.message);
                }
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    }

    useEffect(() => {
        setRequestFun();
    }, []);

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li className="inline-flex items-center">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                Dashboard
                            </Link>
                        </li>
                        <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Approve Request</span>
                        </div>
                        </li>
                    </ol>
                </nav>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto lg:overflow-x-visible sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="">
                                <table className="table-style">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Name
                                            </th>
                                            <th scope="col" className=" text-left px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Email
                                            </th>
                                            <th scope="col" className=" text-left px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Role
                                            </th>
                                            <th scope="col" className=" text-left px-3 py-3.5 text-sm font-semibold text-gray-900">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className=" bg-white">
                                        {Array.isArray(setRequest) ? (
                                            setRequest.map((person) => (
                                            <>
                                            <tr key={person.email}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm">{person.name}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm">{person.email}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20`}>                                                       
                                                       {person.role}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset ${person.profile_update == 0 ? 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20' : 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20'}`}>                                                       
                                                            {person.profile_update == 1 ? (
                                                                <>
                                                                    <MdPendingActions  className="mt-1 mr-1"/> <h4>Pending</h4>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaCheckDouble className="mt-1 mr-1"/> <h4>Approved</h4>
                                                                </>
                                                            )}
                                                    </span>
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <div className="flex">
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button className="flex items-center  ">
                                                                        <span className="sr-only">Open options</span>
                                                                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </Menu.Button>
                                                                </div>

                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-[120px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="py-3 px-3 ">
                                                                            <Menu.Item>
                                                                                    {person.profile_update == 1 ? (
                                                                                        <>
                                                                                            <Link className={`flex flex-row justify-between text-yellow-400 hover:text-yellow-700`} onClick={() => updateStatus(person.id)}>
                                                                                                <h4>Approve Now</h4>
                                                                                            </Link>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Link className={`flex flex-row justify-between text-green-600 hover:text-green-900`}>
                                                                                                <h4>Approved</h4>
                                                                                            </Link>
                                                                                        </>
                                                                                    )}
                                                                            </Menu.Item>
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                            
                                                        </div>
                                                </td>
    
                                            </tr>
                                                </>
                                            ))
                                        ) 
                                        : (
                                            <tr>
                                                <td colSpan="4" className="text-center">No Record Found</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
        
    );
}
