import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { FaUserCheck } from "react-icons/fa";
import { FaUserAltSlash } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiSendPlaneFill } from "react-icons/ri";
import { TiCamera } from "react-icons/ti";

function ProfileData() {
    const [getuser, setGetUser] = useState([]);

    var userData = localStorage.getItem("user");
    var user = JSON.parse(userData);
    var userId = user.id;

    const getUser = () => {
        try {

            axios.get(`https://projects.bzbeetech.com/busybeetech/api/profile/${userId}`).then((response) => {
                setGetUser(response.data.employee);
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };
    const ProfileImage = getuser.image 
  ? `https://projects.bzbeetech.com/busybeetech/public/upload/${getuser.image}`
  : process.env.PUBLIC_URL + '/profile.webp';
    const CnicFront = `https://projects.bzbeetech.com/busybeetech/public/upload/${getuser.cnic_front}`;
    const CnicBack = `https://projects.bzbeetech.com/busybeetech/public/upload/${getuser.cnic_back}`;


    const [formData, setFormData] = useState({
        user_id: '',
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post('https://projects.bzbeetech.com/busybeetech/api/profile',{
            user_id : getuser.id
          });
    
          if (response.data.status === 'success') {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          handleApiError(error);
        }
      };
    
      const handleApiError = (error) => {
        if (error.isAxiosError && !error.response) {
          toast.error('Network is not stable');
        } else {
          console.error('API Error:', error.response.data);
          toast.error('An unexpected error occurred while processing the request.');
        }
      };

    useEffect(() => {
        getUser();
    }, []);
    return (
        <>
            <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            Dashboard
                        </Link>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Profile</span>
                        </div>
                    </li>
                </ol>
            </nav>
            <div className="flex lg:flex-row md:flex-row flex-col justify-between">
                <div className="lg:w-[35%] md:w-[35%]  w-full h-[35%] shadow rounded-lg">
                    <div className="flex justify-end">
                        <div className="mr-5">
                            <Link to={`/uploadprofile/${getuser.id}`} type="button" className="flex h-9 rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                                <span>Upload Image</span>
                                <span className="pl-2 mt-1"><TiCamera  /></span>
                            </Link> 
                        </div>
                    </div>
                    <div className=" w-full pt-8 flex flex-col  items-center ">
                        <div style={{ width: "200px", height: "200px" }}>
                            <img
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                }}
                                src={ProfileImage}
                                alt=""
                                className="w-[150px]"
                            />
                        </div>

                        <div className="pt-3 text-gray-700 text-xl">
                            {getuser.name ? getuser.name : ""} {getuser.last_name ? getuser.last_name : ""}
                        </div>

                        <div className=" text-gray-700 pt-5">{getuser.designation ? getuser.designation : ""}</div>

                        <div className="pt-2 text-gray-700">{getuser.address ? getuser.address : ""}</div>
                    </div>

                    <div className=" w-full pt-8 flex flex-col  items-center ">
                        <h1 className="text-xl text-gray-600 font-semibold mb-2">CNIC Front Image:-</h1>
                        <div style={{ width: "200px", height: "200px" }}>
                            <img
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                }}
                                src={CnicFront}
                                alt=""
                                className="w-[150px]"
                            />
                        </div>
                    </div>
                    <div className=" w-full pt-8 flex flex-col  items-center ">
                        <h1 className="text-xl text-gray-600 font-semibold mb-2">CNIC Back Image:-</h1>
                        <div style={{ width: "200px", height: "200px" }}>
                            <img
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                }}
                                src={CnicBack}
                                alt=""
                                className="w-[150px]"
                            />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden lg:w-[60%]  md:w-[60%] w-full lg:mt-0 md:mt-0 mt-4 bg-white shadow sm:rounded-lg">
                    <div className="flex justify-between items-center">
                        <div className="px-4 py-4 sm:px-6">
                            <h3 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">General Details</p>
                        </div>
                        <div className="mr-5">
                            {getuser.profile_update == 0 || getuser.role == 'Super Admin' ?(
                                <Link to={`/editprofile/${getuser.id}`} type="button" className="flex h-9 rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                                    <span>Update Profile</span>
                                    <span className="pl-2 mt-1"><FaUserEdit /></span>
                                </Link>
                            )
                            
                            :(
                                <>
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="submit" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                                            Send Update Request
                                            <span className="ml-2 mt-1">
                                                <RiSendPlaneFill  />
                                            </span>
                                        </button>
                                        <ToastContainer />
                                    </div>
                                </form>
                                </>
                            )} 
                        </div>
                    </div>
                    <div className="border-t border-gray-100 flex justify-around">
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">First Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {getuser.name ? getuser.name : ""}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Email Address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.email ? getuser.email : ""}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Gender</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.gender ? getuser.gender : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">DOB</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.dob ? getuser.dob : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">CNIC</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.cnic ? getuser.cnic : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Permenant Address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.permanent_address ? getuser.permanent_address : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Emergency Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {getuser.emergency_name ? getuser.emergency_name : ""}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Relation</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.emergency_relation ? getuser.emergency_relation : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Account No:</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {getuser.account_no ? getuser.account_no : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Job Type</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> 
                                    {getuser.job_type === "full_time" &&(
                                        <>
                                            <span className="inline-flex justify-center items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ">
                                                Full Time
                                            </span>
                                        </>
                                    )}
                                    {getuser.job_type === "part_time" &&(
                                        <>
                                            <span className="inline-flex justify-center items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ">
                                                Part Time
                                            </span>
                                        </>
                                    )}
                                    {getuser.job_type === "casual" &&(
                                        <>
                                            <span className="inline-flex justify-center items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ">
                                                Casual
                                            </span>
                                        </>
                                    )}
                                </dd>
                            </div>
                        </dl>
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Last Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {getuser.last_name ? getuser.last_name : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Phone</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.phone ? getuser.phone : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Education</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.education ? getuser.education : ""}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Joining Date</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.start_date ? getuser.start_date : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Current Address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.address ? getuser.address : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Position</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{getuser.designation ? getuser.designation : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Emergency Phone</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {getuser.emergency_phone ? getuser.emergency_phone : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Bank Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {getuser.bank_name ? getuser.bank_name : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Branch</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {getuser.branch ? getuser.branch : ""}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-900">Pay Rate</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> 
                                    {getuser.job_pay_type === "annual" &&(
                                        <>
                                            <span className="inline-flex justify-center items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ">
                                                Annual
                                            </span>
                                        </>
                                    )}
                                    {getuser.job_pay_type === "monthly" &&(
                                        <>
                                            <span className="inline-flex justify-center items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ">
                                                Monthly
                                            </span>
                                        </>
                                    )}
                                    {getuser.job_pay_type === "hourly" &&(
                                        <>
                                            <span className="inline-flex justify-center items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ">
                                                Hourly
                                            </span>
                                        </>
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
export default ProfileData;