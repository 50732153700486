import DesktopSidebar from "../Components/DesktopSidebar";
import AddEmployee_form from "../Components/AddEmployee_form";

export default function AddEmployee() {
    return (
        <>
            <div>
                <DesktopSidebar />
                <div className="lg:pl-72">
                    <main className="py-10">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <AddEmployee_form />
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
