import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { IoMdEye } from "react-icons/io";
import { Link } from "react-router-dom";
import axios from "axios";
import { Fragment , useRef} from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { FaUserCheck } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { FaUserAltSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { BiSolidFilePdf } from "react-icons/bi";

export default function Table({ person }) {

    const [show, setShow] = useState(false);
    const [value, setValue] = useState('');
    const [edit, setEdit] = useState(false);

    const [getjob, setGetJob] = useState('');

    const [getuser, setGetUser] = useState([]);
    const [getrole, setGetRole] = useState([]);

    var userData = localStorage.getItem('user');
    var user = JSON.parse(userData);
    var userId = user.id;
  
   
   const handleEditClick = (person) => {
        setEdit(true);
    };

    const getJobs = () => {
        
        try {
            axios.get(`https://projects.bzbeetech.com/busybeetech/api/job`).then((response) => {
                setGetJob(response.data.job);
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    }

    const getUser = () => {
        try {
            axios.get(`https://projects.bzbeetech.com/busybeetech/api/profile/${userId}`).then((response) => {
            
                if(response.data.status === "success"){
                var role_id = response.data.employee.role_id;
                setGetUser(response.data.employee);
                getRolePermision(role_id);
            }
            });
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };

    const getRolePermision = (role_id) => {
        try {
        axios.get(`https://projects.bzbeetech.com/busybeetech/api/role/${role_id}`).then((response) => {
            if(response.data.status === "success"){
                setGetRole(response.data.role);
            }
            }); 
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };

   //Update Status
    const updatePost = (id) => {

        try {

            axios.get(`https://projects.bzbeetech.com/busybeetech/api/status/${id}`).then((response) => {
                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    getJobs()
                }else {
                    toast.error(response.data.message);
                }
            });
        } catch (error) {
            console.error("API Error:", error);
        }

    }
    const cancelButtonRef = useRef(null)

    // Delete Job
    const handleDelete = async  (value) => {
        try {
            
            const response  =await axios.delete(`https://projects.bzbeetech.com/busybeetech/api/job/${value}`);
            if (response.data.status === "success") {
                toast.success(response.data.message);
                getJobs();
            }
        } catch (error) {
            console.error("API Error:", error);
        }
    };


    
    const reportTemplateRef = useRef(null);

    //PDF  Download
    const handleGeneratePdf = () => {
        const doc = new jsPDF({
          orientation: "p",
          unit: "pt",
          format: "a3",
        });
      
        const pdfOptions = {
          windowWidth: 1400,
          x: 10,
          y: 10,
          html2canvas: { scale: 0.57 },
          width: 900,
        };
      
        const pdfTableRef = document.createElement("div");
        pdfTableRef.className = "pdf-table-wrapper";
        pdfTableRef.innerHTML = reportTemplateRef.current.innerHTML;

        const pdfStatusColumns = pdfTableRef.querySelectorAll(
          ".pdf-table-wrapper tr td:nth-child(5)"
        );
        const pdfActionTdColumns = pdfTableRef.querySelectorAll(
          ".pdf-table-wrapper tr td:nth-child(6)"
        );
        const pdfActionTheadColumns = pdfTableRef.querySelectorAll(
          ".pdf-table-wrapper thead tr th:nth-child(6)"
        );
      
        pdfStatusColumns.forEach((column) => {
          column.classList.add("hide-design");
      
          // Remove unwanted classes from the span inside the column
          const statusSpan = column.querySelector("span");
          if (statusSpan) {
            statusSpan.classList.remove(
              "text-green-600",
              "bg-green-50",
              "hover:text-green-900",
              "ring-green-600/20",
              "ring-1",
              "ring-inset"
            );
            statusSpan.style.color = "black";
          }
        });
      
        pdfActionTdColumns.forEach((column) => {
          column.style.display = "none";
        });
      
        pdfActionTheadColumns.forEach((column) => {
          column.style.display = "none";
        });
      
        const tableHtml = pdfTableRef.innerHTML;
        doc.html(tableHtml, {
          ...pdfOptions,
          async callback(doc) {
            await doc.save("document");
          },
        });
    };

    //Excel Sheet Download
    const downloadExcel = () => {
        const cleanData = (obj, fieldsToRemove, fieldsToReplace) => {
          const cleanedObj = { ...obj };
          fieldsToRemove.forEach((field) => delete cleanedObj[field]);
      
          // Replace the keys with the new labels
          const replacedObj = {};
          Object.keys(cleanedObj).forEach((key) => {
            replacedObj[fieldsToReplace[key] || key] = cleanedObj[key];
          });
      
          return replacedObj;
        };
      
        // Fields to remove
        const fieldsToRemove = [
          "id",
          "user_id",
          "status",
          "question_status",
          "created_at",
          "updated_at",
        ];
      
        // Fields to replace
        const fieldsToReplace = {
          position: "Position",
          job_category: "Job Category",
          job_category: "Job Category",
          vacancy: "Vacancy",
          experience: "Experience",
          post_date: "Post Date",
          expire_date: "Expire Date",
          salary_from: "Salary From",
          salary_to: "Salary To",
          city: "City",
          education: "Education",
          description: "Description",
        };
      
        const combinedData = [];
      
        if (Array.isArray(getjob)) {
            getjob.forEach((person) => {
            const cleanedPerson = cleanData(person, fieldsToRemove, fieldsToReplace);
            combinedData.push(cleanedPerson);
          });
        } else {
          return;
        }
      
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(combinedData);
        XLSX.utils.book_append_sheet(wb, ws, "Employee Data");
        XLSX.writeFile(wb, "Jobs.xlsx");
    };

    useEffect(() => {
        getJobs();
        getUser();
        getRolePermision();
    }, []);
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                        </svg>
                        <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            Dashboard
                        </Link>
                    </li>
                    <li aria-current="page">
                    <div className="flex items-center">
                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                        </svg>
                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Job</span>
                    </div>
                    </li>
                </ol>
                {getrole.role === getuser.role && getrole.edit_employee === "1" && getrole.view_employee === "1" && 
                getrole.update_profile === "1" && getrole.meeting_schedule === "1" && getrole.post_job === "1" && 
                getrole.view_applicant === "1" &&(
                    <Link to="/addjob" type="button" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                        <span>Create</span><span className="pl-2 mt-1"><FaPlusCircle /></span> 
                    </Link>
                )}
                {getrole.role === getuser.role && getrole.edit_employee === "0" && getrole.view_employee === "1" && 
                getrole.update_profile === "0" && getrole.meeting_schedule === "1" && getrole.post_job === "1" && 
                getrole.view_applicant === "1" &&(
                    <Link to="/addjob" type="button" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                        <span>Create</span><span className="pl-2 mt-1"><FaPlusCircle /></span> 
                    </Link>
                )}
                {getrole.role === getuser.role && getrole.edit_employee === "0" && getrole.view_employee === "0" && 
                getrole.update_profile === "0" && getrole.meeting_schedule === "0" && getrole.post_job === "0" && 
                getrole.view_applicant === "0" &&(
                    <Link title="you dont have an access" type="button" className="cursor-not-allowed flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                        <span>Create</span><span className="pl-2 mt-1"><FaPlusCircle /></span> 
                    </Link>
                )}
            </nav>
            <div className="bg-yellow-500 flex justify-center items-center p-2 rounded" style={{width:"150px"}}>
                    <div className="space-x-2">
                        <button onClick={handleGeneratePdf} className="p-2 bg-white rounded shadow hover:bg-gray-100 transition-colors duration-150">
                            <BiSolidFilePdf className="text-red-800" size={20} />
                        </button>

                        <button onClick={downloadExcel} className="p-2 bg-white rounded shadow hover:bg-gray-100 transition-colors duration-150">
                            <PiMicrosoftExcelLogoFill className="text-green-600" size={20} />
                        </button>
                    </div>
            </div>
            <div className="mt-8 flow-root" ref={reportTemplateRef} id="content">
                <div className="-mx-4 -my-2 overflow-x-auto lg:overflow-x-visible sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                        <div className="">
                            <table className="table-style">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Position
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Vacancy
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Experience
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Education
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Status
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className=" bg-white">
                                    {Array.isArray(getjob) ? (
                                        getjob.map((person) => (
                                            <>
                                                <tr key={person.position}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{person.position}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {person.vacancy}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.experience}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.education}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <span className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${person.status == 1 ? 'text-green-600 bg-green-50 hover:text-green-900 ring-green-600/20 ' : 'text-red-600 bg-red-50 hover:text-red-900 ring-red-600/20'}`}>
                                                            {person.status == 1 ? (
                                                                <>
                                                                    <FaUserCheck /> <h4 className="ml-2">Active</h4>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaUserAltSlash /> <h4 className="ml-2">Inactive</h4>
                                                                </>
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <div className="flex">
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button className="flex items-center  ">
                                                                        <span className="sr-only">Open options</span>
                                                                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </Menu.Button>
                                                                </div>

                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-[120px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="py-3 px-3 ">
                                                                            {getrole.role === getuser.role && getrole.edit_employee === "1" && getrole.view_employee === "1" && 
                                                                            getrole.update_profile === "1" && getrole.meeting_schedule === "1" && getrole.post_job === "1" && 
                                                                            getrole.view_applicant === "1" &&(
                                                                                <>
                                                                                    <Menu.Item >
                                                                                        <Link to={`/editjob/${person.id}`} style={{ marginLeft: '0.75rem !important' }} className="flex flex-row justify-between text-green-600 hover:text-green-900" onClick={() => handleEditClick(person)}>
                                                                                            <CiEdit />
                                                                                            <h4>Edit</h4>
                                                                                        </Link>

                                                                                    </Menu.Item>
                                                                                    <Menu.Item >
                                                                                        <Link to={`/viewjob/${person.id}`} style={{ marginLeft: '0.75rem !important' }} className="flex flex-row justify-between text-blue-400 hover:text-blue-600" onClick={() => handleEditClick(person)}>
                                                                                        <IoMdEye />
                                                                                            <h4>View</h4>
                                                                                        </Link>

                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        <Link className="flex flex-row justify-between text-red-600 hover:text-red-900 " onClick={() => {setValue(person.id);handleDelete(person.id);}}>

                                                                                            <RiDeleteBin6Line />
                                                                                            <h4>Delete</h4>
                                                                                        </Link>
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        <Link className={`flex flex-row justify-between ${person.status == 1 ? 'text-red-600 hover:text-red-900' : 'text-green-600 hover:text-green-900'}`} onClick={() => updatePost(person.id)}>
                                                                                            {person.status == 1 ? (
                                                                                                <>
                                                                                                    <FaUserAltSlash className="mt-1" /> <h4>Inactive</h4>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <FaUserCheck className="mt-1" /> <h4>Active</h4>
                                                                                                </>
                                                                                            )}
                                                                                        </Link>
                                                                                    </Menu.Item>
                                                                                </>
                                                                            )}
                                                                            {getrole.role === getuser.role && getrole.edit_employee === "0" && getrole.view_employee === "1" && 
                                                                            getrole.update_profile === "0" && getrole.meeting_schedule === "1" && getrole.post_job === "1" && 
                                                                            getrole.view_applicant === "1" &&(
                                                                                <span className="text-[#d50000]">You Don't <br/> have an access</span>
                                                                            )}
                                                                            {getrole.role === getuser.role && getrole.edit_employee === "0" && getrole.view_employee === "0" && 
                                                                            getrole.update_profile === "0" && getrole.meeting_schedule === "0" && getrole.post_job === "0" && 
                                                                            getrole.view_applicant === "0" &&(
                                                                                <span className="text-[#d50000]">You Don't <br/> have an access</span>
                                                                            )}
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>

                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    )
                                        : (
                                            <tr>
                                                <td colSpan="6" className="text-center">No Record Found</td>
                                            </tr>
                                        )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
