import DesktopSidebar from "../Components/DesktopSidebar";
import TopNav from "../Components/TopNav";
import Home from "../Components/Home";

export default function Dashboard() {
    return (
        <>
            <div>
                <DesktopSidebar />
                <div className="lg:pl-72">
                    <main className="">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <Home />
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
