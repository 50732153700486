import { React, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { BsSend } from "react-icons/bs";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const AddLeave = () => {
    // Local storage Get Data
    var userData = localStorage.getItem('user');
    var user = JSON.parse(userData);
    var userId = user.id;  
    const [getLeave, setGetLeave] = useState('');
    const [getType, setGetType] = useState('');
    const initialFormData1 = {
        full_day: '',
        half_day: '',
        short_leave_to: '',
        short_leave_from: '',
    };
    const [formData1, setFormData1] = useState({ ...initialFormData1 });
    const handleQuillChange = (value) => {
        setGetLeave((prevPolicy) => ({
            ...prevPolicy,
            reason: value,
        }));
    };
    const handleTypeChange = (e) => {
        setGetType(e.target.value);
    };
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        if (type === 'radio') {
            setFormData1((prevFormData1) => ({
                ...prevFormData1,
                notificationMethod1: value,
            }));
        } else {
            setFormData1((prevFormData1) => ({
                ...prevFormData1,
                [name]: value,
            }));
        }
    };
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];
    const handleApiError = (error) => {
        if (error.isAxiosError && !error.response) {
            toast.error('Network is not stable');
        } else {
            console.error('API Error:', error.response.data);
            toast.error('An unexpected error occurred while processing the request.');
        }
    };
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        try {
            const userData = {
                reason: getLeave.reason,
                user_id: userId,
                full_day: formData1.full_day,
                half_day: formData1.half_day,
                short_leave_to: formData1.short_leave_to,
                short_leave_from: formData1.short_leave_from,
                leave_type: getType,
            };
            const response = await axios.post('https://projects.bzbeetech.com/busybeetech/api/leave', userData);
            if (response.data.status === 'success') {
                toast.success(response.data.message);
            } else if (response.data.errors) {
                Object.entries(response.data.errors).forEach(([field, messages]) => {
                    messages.forEach((message) => {
                        toast.error(`${field}: ${message}`);
                    });
                });
            }
        } catch (error) {
            handleApiError(error);
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit1}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                <li className="inline-flex items-center">
                                    <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                        Dashboard
                                    </Link>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Leave</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Leave Type <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        name="leave_type"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                        value={getType.leave_type}
                                        onChange={handleTypeChange}
                                    >
                                        <option>Select Leave Type</option>
                                        <option value="short">Short Leave</option>
                                        <option value="half">Half Day Leave</option>
                                        <option value="full">Full Day Leave</option>
                                    </select>
                                </div>
                            </div>
                            {getType === 'short' && (
                                <>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Short Leave Time Duration <span className="text-[#d50000]">*</span>
                                        </label>
                                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="sm:col-span-3">
                                                <input
                                                    type="time"
                                                    name="short_leave_to"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                    value={formData1.short_leave_to}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="sm:col-span-3">
                                                <input
                                                    type="time"
                                                    name="short_leave_from"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                    value={formData1.short_leave_from}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {getType === 'half' && (
                                <>
                                    <div className="sm:col-span-3 mt-2">
                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Half Day Leave Time Slot<span className="text-[#d50000]">*</span>
                                        </label>
                                        <div className="sm:col-span-3">
                                            <input
                                                type="datetime-local"
                                                name="half_day"
                                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                value={formData1.half_day}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {getType === 'full' && (
                                <>
                                    <div className="sm:col-span-3 mt-2">
                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Full Day Leave Date<span className="text-[#d50000]">*</span>
                                        </label>
                                        <div className="sm:col-span-3">
                                            <input
                                                type="date"
                                                name="full_day"
                                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ffae37] sm:text-sm sm:leading-6"
                                                value={formData1.full_day}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="sm:col-span-full">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Reason <span className="text-[#d50000]">*</span>
                                </label>
                                <div className="mt-2">
                                    <ReactQuill
                                        name="reason"
                                        theme="snow"
                                        value={getLeave.reason}
                                        onChange={handleQuillChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="submit" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold 
                    text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 
                    focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                        Send <span className="ml-2 mt-1"><BsSend  /></span>
                    </button>
                </div>
            </form>
            <ToastContainer />
        </>
    );
};
export default AddLeave;