import DesktopSidebar from "../Components/DesktopSidebar";
import MeetingSchedule from "../Components/MeetingSchedule";

export default function MeetingSchedulePage() {
    return (
        <>
            <div>
                <DesktopSidebar />
                <div className="lg:pl-72">
                    <main className="py-10">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <MeetingSchedule />
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
