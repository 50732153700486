import { useState, useEffect ,Fragment } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaPlusCircle } from "react-icons/fa";
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { FaUserCheck } from "react-icons/fa";
import { FaUserAltSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PolicyList() {
    
    const [value, setValue] = useState('');

    const [getPolicy, setGetPolicy] = useState([]);

    const FetchPolicy = async (id) => {
        try {
            const response = await axios.get(`https://projects.bzbeetech.com/busybeetech/api/policy`);
            if (response.data.status === "success") {
                setGetPolicy(response.data.policy)
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }

    }

    const handleDelete = async (value) => {
        try {
            const response = await axios.delete(`https://projects.bzbeetech.com/busybeetech/api/policy/${value}`);
            if (response.data.status === "success") {
                toast.success(response.data.message);
                FetchPolicy();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.isAxiosError && !error.response) {
                toast.error("Network is not stable");    
            } else {
                console.error('API Error:', error.response.data);
                toast.error("An unexpected error occurred while processing the request.");
            }
        }
    };

    useEffect(() => {
        FetchPolicy();
    }, []);

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <nav className="flex mb-4 justify-between" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                        </svg>
                        <Link to="/dasboard" type="button" className="finline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            Dashboard
                        </Link>
                    </li>
                    <li aria-current="page">
                    <div className="flex items-center">
                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                        </svg>
                        <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Policies</span>
                    </div>
                    </li>
                </ol>
                <Link to="/addPolicy" type="button" className="flex rounded-md bg-[#ffae37] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#ffae37] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ffae37]">
                    <span>Create</span><span className="pl-2 mt-1"><FaPlusCircle /></span> 
                </Link>
            </nav>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto lg:overflow-x-visible sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                        <div className="">
                            <table className="min-w-full ">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Policies
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody key="text" className=" bg-white">
                                    {Array.isArray(getPolicy) ? (
                                        getPolicy.map((person) => (
                                           <>
                                            <tr key={person.policy}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6" dangerouslySetInnerHTML={{ __html: person.policy }} />
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <div className="flex">
                                                        <Menu as="div" className="relative inline-block text-left">
                                                            <div>
                                                                <Menu.Button className="flex items-center  ">
                                                                    <span className="sr-only">Open options</span>
                                                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>

                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-[120px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-3 px-3 ">
                                                                        <Menu.Item >
                                                                            <Link to={`/editPolicy/${person.id}`} style={{ marginLeft: '0.75rem !important' }} className="flex flex-row justify-between text-green-600 hover:text-green-900">
                                                                                <CiEdit />
                                                                                <h4>Edit</h4>
                                                                            </Link>
                                                                            
                                                                        </Menu.Item>
                                                                        <Menu.Item>
                                                                            <Link className="flex flex-row justify-between text-red-600 hover:text-red-900 " onClick={() => {setValue(person.id);handleDelete(person.id);}}>

                                                                                <RiDeleteBin6Line />
                                                                                <h4>Delete</h4>
                                                                            </Link>
                                                                        </Menu.Item>
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                </td>
                                            </tr>
                                            </>
                                        ))
                                        ) 
                                        : (
                                            <tr>
                                                <td colSpan="4" className="text-center">No Record Found</td>
                                            </tr>
                                        )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
