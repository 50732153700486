import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard';
import User from './pages/User';
import Profile from './pages/Profile';
import Addjob from './pages/Addjob';
import Editjob from './pages/Editjob';
import Employee from './pages/Employee';
import AddEmployee from './pages/AddEmployee';
import EditEmployee from './pages/EditEmployee';
import EditProfile from './pages/EditProfile';
import UploadProfile from './pages/UploadProfile';
import Viewjob from './pages/Viewjob';
import Login from './pages/Login';
import AddRolePage from './pages/AddRolePage';
import RoleListPage from './pages/RoleListPage';
import EditRolePage from './pages/EditRolePage';
import MemberListPage from './pages/MemberListPage';
import AddMemberPage from './pages/AddMemberPage';
import EditMemberPage from './pages/EditMemberPage';
import RequestApprovePage from './pages/RequestApprovePage';
import ApplicantListPage from './pages/ApplicantListPage';
import ApplicantDetail from './pages/ApplicantDetail';
import MeetingSchedulePage from './pages/MeetingSchedulePage';
import AddMeetingSchedulePage from './pages/AddMeetingSchedulePage';
import ReportPage from './pages/ReportPage';
import TimePage from './pages/TimePage';
import AddTimeSchedulePage from './pages/AddTimeSchedulePage';
import EditTimePage from './pages/EditTimePage';
import PolicyPage from './pages/PolicyPage';
import AddPolicyPage from './pages/AddPolicyPage';
import EditPolicyPage from './pages/EditPolicyPage';
import LeavePage from './pages/LeavePage';
import AddLeavePage from './pages/AddLeavePage';
import AttendancePage from './pages/AttendancePage';

const ProtectedRoute = ({ children }) => {
    const userData = localStorage.getItem('user');
    if (!userData) {
        return <Navigate to="/" replace />;
    }
    return children;
};

const RedirectToDashboardIfLoggedIn = ({ children }) => {
    const userData = localStorage.getItem('user');
    if (userData) {
        return <Navigate to="/dashboard" replace />;
    }
    return children;
};

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RedirectToDashboardIfLoggedIn><Login /></RedirectToDashboardIfLoggedIn>} />
                <Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path="/job" element={<ProtectedRoute><User /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path="/addjob" element={<ProtectedRoute><Addjob /></ProtectedRoute>} />
                <Route path="/editjob/:id" element={<ProtectedRoute><Editjob /></ProtectedRoute>} />
                <Route path="/viewjob/:id" element={<ProtectedRoute><Viewjob /></ProtectedRoute>} />
                <Route path="/employee" element={<ProtectedRoute><Employee /></ProtectedRoute>} />
                <Route path="/addemployee" element={<ProtectedRoute><AddEmployee /></ProtectedRoute>} />
                <Route path="/editemployee/:id" element={<ProtectedRoute><EditEmployee /></ProtectedRoute>} />
                <Route path="/editprofile/:id" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
                <Route path="/uploadprofile/:id" element={<ProtectedRoute><UploadProfile /></ProtectedRoute>} />
                <Route path="/role" element={<ProtectedRoute><RoleListPage /></ProtectedRoute>} />
                <Route path="/addrole" element={<ProtectedRoute><AddRolePage /></ProtectedRoute>} />
                <Route path="/roleedit/:id" element={<ProtectedRoute><EditRolePage /></ProtectedRoute>} />
                <Route path="/member" element={<ProtectedRoute><MemberListPage /></ProtectedRoute>} />
                <Route path="/addmember" element={<ProtectedRoute><AddMemberPage /></ProtectedRoute>} />
                <Route path="/editmember/:id" element={<ProtectedRoute><EditMemberPage /></ProtectedRoute>} />
                <Route path="/request" element={<ProtectedRoute><RequestApprovePage /></ProtectedRoute>} />
                <Route path="/applicant" element={<ProtectedRoute><ApplicantListPage /></ProtectedRoute>} />
                <Route path="/applicant/view/:id" element={<ProtectedRoute><ApplicantDetail /></ProtectedRoute>} />
                <Route path="/meeting_schedule" element={<ProtectedRoute><MeetingSchedulePage /></ProtectedRoute>} />
                <Route path="/create/schedule/:id" element={<ProtectedRoute><AddMeetingSchedulePage /></ProtectedRoute>} />
                <Route path="/report" element={<ProtectedRoute><ReportPage /></ProtectedRoute>} />
                <Route path="/time" element={<ProtectedRoute><TimePage /></ProtectedRoute>} />
                <Route path="/addTimeSchedule" element={<ProtectedRoute><AddTimeSchedulePage /></ProtectedRoute>} />
                <Route path="/editTime/:id" element={<ProtectedRoute><EditTimePage /></ProtectedRoute>} />
                <Route path="/policy" element={<ProtectedRoute><PolicyPage /></ProtectedRoute>} />
                <Route path="/addPolicy" element={<ProtectedRoute><AddPolicyPage /></ProtectedRoute>} />
                <Route path="/editPolicy/:id" element={<ProtectedRoute><EditPolicyPage /></ProtectedRoute>} />
                <Route path="/leave" element={<ProtectedRoute><LeavePage /></ProtectedRoute>} />
                <Route path="/addLeave" element={<ProtectedRoute><AddLeavePage /></ProtectedRoute>} />
                <Route path="/attendance" element={<ProtectedRoute><AttendancePage /></ProtectedRoute>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
